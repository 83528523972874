<template>
    <div>
        <CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
            <template slot="Filtros">
                <label  class="mr-1">Estatus</label>
                <select v-model="Filtro.EstatusOrden" class="form-control form-select mr-2" @change="Lista">
                    <option value="">Todos</option>
                    <option value="Creada">Creadas</option>
                    <option value="Pendiente">Pendiente</option>
                    <option value="Autorizada">Autorizada</option>
                    <option value="EnTransito">En Transito</option>
                    <option value="Entregada">Entregadas</option>
                    <option value="Cancelada">Canceladas</option>
                </select>

                <!-- <button type="button" @click="updateListaFacturacion()" v-b-tooltip.hover.Top title="Actualizar Facturación" class="btn btn-primary btn-sm mr-2" :disabled="IsLoader.BtnLoad.Bloq">
						<i :class="IsLoader.BtnLoad.Icon"></i>
				</button> -->
            </template>
            <template slot="header">
                <th class="td-sm"></th>
                <th>Folio</th>
                <th>Nombre Comerial</th>
                <th v-if="!userClean">Fecha Entrega</th> 
                <th class="text-right">Total</th>
                <th class="text-center">Estatus</th>
                <th class="text-center">Acciones</th>
                <th class="text-center">Facturación</th>
            </template>

            <template slot="body">
                <tr v-for="(lista, index) in ListaArrayRows" :key="index">
                    <td class="td-sm"></td>
                    <td>
                        <CAvatar v-if="segurity.VIEWALLORDENES && segurity.VIEWALLORDENES != undefined && segurity.VIEWALLORDENES" 
                        :fullname="lista.creador.NombreCompleto" :color="lista.creador.color" :size="25" :radius="50" v-b-tooltip.hover.Top :title="lista.creador.NombreCompleto" style="margin-bottom: 0px;"/>&nbsp;
                        <b>{{ lista.FolioOrden }}</b>
                    </td>
                    
                    <td>{{ $limitCharacters(lista.cliente.NombreComercial,45) }}</td>
                    <td v-if="!userClean">{{ $getCleanDate(lista.FechaEntrega,false) }}</td>
                    <td class="text-right">{{ $formatNumber(lista.Total,'$') }}</td>


                    <td class="text-center">
                        <b-badge v-if="lista.EstatusOrden == 'Creada'" pill variant="secondary">{{ lista.EstatusOrden }}</b-badge>
                        <b-badge v-if="lista.EstatusOrden == 'Autorizada'" pill variant="warning">{{ lista.EstatusOrden }}</b-badge>
                        <b-badge v-if="lista.EstatusOrden == 'Pendiente'" pill variant="primary">{{ lista.EstatusOrden }}</b-badge>
                        <b-badge v-if="lista.EstatusOrden == 'EnTransito'" pill variant="info">{{ lista.EstatusOrden }}</b-badge>
                        <b-badge v-if="lista.EstatusOrden == 'Entregada'" pill variant="success">{{ lista.EstatusOrden }}</b-badge>
                        <b-badge v-if="lista.EstatusOrden == 'Cancelada'" pill variant="danger">{{ lista.EstatusOrden }}</b-badge>
                    </td>

                    <td class="text-center">
                        <CBtnAccion :pGoRoute="ConfigList.GoRoute" :pShowBtnEdit="lista.EstatusOrden !== 'Cancelada'" :pShowBtnDelete="lista.EstatusOrden == 'Creada' && segurity.Delete" :pIsModal="false" :pId="lista.IdOrdenProduccion" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
                            <template slot="btnaccion">
                                
                                <span v-if="itsMe">
                                    <button class="btn btn-secondary btn-sm btn-icon dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" title="Mas acciones">
                                        <i class="far fa-ellipsis-v"></i>
                                    </button>
                                    <div class="dropdown-menu">
                                        <a v-if="segurity.viewReporPDF" class="dropdown-item" @click="getPdfOrdenProduccion(lista);"> <i class="fa fa-file-pdf" style="color: #0096ED;"></i> Ver Orden de Produccion</a>
                                        <a v-if="lista.EstatusOrden == 'Creada' && segurity.EnviaOrdenProd" class="dropdown-item" @click="cambioEstatusOrden(lista,'EnviaOrden')"> <i class="fas fa-paper-plane" style="color:#17a2b8;"></i> Enviar Orden de Produccion</a>
                                        <a v-if="lista.EstatusOrden == 'Pendiente' && segurity.AutorizaOrdenProd" class="dropdown-item" @click="cambioEstatusOrden(lista,'AutorizaOrden')"> <i class="fas fa-check-double" style="color: #28a745;"></i> Autorizar Orden Produccion</a>
                                        <a v-if="lista.EstatusOrden == 'Autorizada' && segurity.EmitrRemisiones" class="dropdown-item" @click="cambioEstatusOrden(lista,'EnTransito')"> <i class="fas fa-truck" style="color: #ffc107;"></i> Iniciar Reparto de Orden</a>
                                        <a v-if="lista.EstatusOrden == 'EnTransito' && segurity.viewRemisiones" class="dropdown-item" @click="verNotaRemision(lista)"> <i class="fas fa-truck" style="color: #ffc107;"></i> Ver Notas de Remision</a>
                                        <a v-if="(lista.EstatusOrden == 'EnTransito' || lista.EstatusOrden == 'Autorizada') && segurity.EntregarOrdenProd" class="dropdown-item" @click="cambioEstatusOrden(lista,'OrdenEntregada')"> <i class="fas fa-check-circle" style="color: #28a745;"></i> Marcar como Entregada</a>
                                        
                                        <template v-if="lista.EstatusOrden != 'Cancelada' && segurity.CancelOrdenProduccion">
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item" @click="cancelarOrden(lista)"> <i class="fas fa-times-octagon" style="color: #dc3545;"></i> Cancelar Orden de Produccion</a>
                                            <a v-if="itsMe" @click="reviertePrevioEstatus(lista)" class="dropdown-item"> <i class="fas fa-fast-backward" style="color: #6c757d;"></i> Revertir a Paso Anterior</a>
                                         
                                        </template>
                                        
                                        
                                    </div>
                                </span>
                                <span v-else>
                                        <template v-if="segurity.viewReporPDF">
                                            <button type="button" @click="getPdfOrdenProduccion(lista);" class="btn btn-info btn-icon" v-b-tooltip.hover.Top title="Ver Orden de Produccion">
                                                <i class="fa fa-file-pdf"></i>
                                            </button>
                                        </template>

                                        <template v-if="lista.EstatusOrden == 'Creada' && segurity.EnviaOrdenProd">
                                            <button @click="cambioEstatusOrden(lista,'EnviaOrden')" type="button" class="btn btn-success btn-icon ml-1" v-b-tooltip.hover.Top title="Enviar Orden de Produccion">
                                                <i class="fas fa-paper-plane"></i>
                                            </button>
                                        </template> 

                                        <template v-if="lista.EstatusOrden == 'Pendiente' && segurity.AutorizaOrdenProd">
                                            <button @click="cambioEstatusOrden(lista,'AutorizaOrden')" type="button" class="btn btn-success btn-icon ml-1" v-b-tooltip.hover.Top title="Autorizar Orden Produccion">
                                                <i class="fas fa-check-double"></i>
                                            </button>
                                        </template>

                                        <template v-if="lista.EstatusOrden == 'Autorizada' && segurity.EmitrRemisiones">
                                            <button @click="cambioEstatusOrden(lista,'EnTransito')" type="button" class="btn btn-warning btn-icon ml-1" v-b-tooltip.hover.Top title="Iniciar Reparto de Orden">
                                                <i class="fas fa-truck"></i>
                                            </button>
                                        </template>

                                        <template v-if="lista.EstatusOrden == 'EnTransito' && segurity.viewRemisiones">
                                            <button @click="verNotaRemision(lista)" type="button" class="btn btn-warning btn-icon ml-1" v-b-tooltip.hover.Top title="Ver Notas de Remision">
                                                <i class="far fa-file-signature"></i>
                                            </button>
                                        </template> 


                                        <template v-if="lista.EstatusOrden == 'EnTransito' && segurity.EntregarOrdenProd">
                                            <button @click="cambioEstatusOrden(lista,'OrdenEntregada')" type="button" class="btn btn-success btn-icon ml-1" v-b-tooltip.hover.Top title="Marcar como Entregada">
                                                <i class="fas fa-check-circle"></i>
                                            </button>
                                        </template> 

                                        <template v-if="lista.EstatusOrden != 'Cancelada' && segurity.CancelOrdenProduccion">
                                            <button @click="cancelarOrden(lista)" type="button" class="btn btn-danger btn-icon ml-1" v-b-tooltip.hover.Top title="Cancelar Orden de Produccion">
                                                <i class="fas fa-times-octagon"></i>
                                            </button>
                                        </template>
                                </span>
                                    <!--  -->
                               
                               

                            </template>
                        </CBtnAccion>
                    </td>

                    <td class="text-center">

                        <template v-if="lista.EstatusOrden == 'Entregada' && lista.EstatusFactura == 'Facturada'">
                            <button @click="getPdfFactura(lista)" type="button" class="btn btn-outline-primary btn-icon ml-1" v-b-tooltip.hover.Top title="Ver Factura">
                                <i class="fas fa-file-pdf"></i>
                            </button>

                            <a :href="$httpFactura.defaults.baseURL+'/getxmlfactura/'+lista.FolioOrden" download="ejemplo.xml" type="button" class="btn btn-outline-primary btn-icon ml-1" target="_blank" v-b-tooltip.hover.Top title="Descargar XML">
                                <i style="margin-top: 6px;" class="fas fa-file-code"></i>
                            </a>
                        </template>

                    </td>
                </tr>
                <CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="8" />
            </template>
        </CList>


        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<FormMotivoCancelacion :poBtnSave="oBtnSave"/>
			</template>
		</CModal>

    </div>
</template>
<script>
import Configs    	    from "@/helpers/VarConfig.js";
import CSinRegistros    from "@/components/CSinRegistros.vue";
import CList            from "@/components/CList.vue";
import CBtnAccion       from "@/components/CBtnAccion.vue";
import CModal           from '@/components/CModal.vue'
import FormMotivoCancelacion from './FormMotivoCancelacion.vue';
import Template from '../../../template/Template.vue';
const EmitEjecuta 	    = "seccionOrdenesProduccion";

export default {
    name: "ListOrdenProduccion",
    props: ['sFiltros'],
    components: {
        CSinRegistros,
        CList,
        CBtnAccion,
        CModal,
        FormMotivoCancelacion,
        Template
    },

    data() {
        return {
            ListaArrayRows: [],
            segurity: 		{},
            ConfigList: {
                Title: 			"Ordenes de Producción",
                IsModal: 		false,
                ShowLoader: 	true,
                BtnReturnShow:  false,
                EmitSeccion: 	EmitEjecuta,
                GoRoute: 		"formulariordenesproduccion",
                TitleFirst:     'Inicio',
                BtnNewShow:     true,
            },
            Filtro: {
                Nombre: 	 "",
                Pagina: 	 1,
                Entrada:     25,
                TotalItem:   0,
                Placeholder: "Folio",
                EstatusOrden: ""
            },
            IsLoader:{
				BtnLoad:{
					Blod: false,
					Icon: 'far fa-redo',
					Orig: 'far fa-redo',
				},
				
				DefaultSpiner: 'fa fa-spinner fa-pulse fa-1x fa-fw',
				DefaultText: ' Espere...',
				BloqCmb1: true,
				BloqCmb2: true,
				BloqCmb3: true,
			},
            
            ConfigModal: {
				ModalTitle: 'Cancelación de Orden',
				ModalSize:  'md',
				EmitSeccion: EmitEjecuta,
			},

            oBtnSave: {
				toast: 		 0,
				IsModal: 	 true,
				DisableBtn:  false,
				EmitSeccion: EmitEjecuta,
			},
            arrayColorUser:[],
            userClean: false,
            itsMe: false,
				
        }
    },
    methods: {
        async Lista() {
            this.ConfigList.ShowLoader = true;

            await this.$http.get("ordenesproduccion", {
                params: {
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada: 	 this.Filtro.Entrada,
                    Pag: 		 this.Filtro.Pagina,
                    EstatusOrden:this.Filtro.EstatusOrden
                },
            })
                .then((res) => {
                    this.Filtro.Pagina    = res.data.data.current_page;
                    this.Filtro.TotalItem = res.data.data.total;
                    let listaRow  = res.data.data.data;
                    this.segurity         = res.data.segurity;

                    if(this.segurity.VIEWALLORDENES && this.segurity.VIEWALLORDENES != undefined && parseInt(this.segurity.VIEWALLORDENES) > 0) {

                        let algo = this;

                        listaRow.forEach(function(element) {
                            element.creador.color = algo.selectUserColor(element.creador.IdUsuario);
                        });
                        this.ListaArrayRows = listaRow;

                    }else {
                        this.ListaArrayRows = listaRow;
                    }
                    

                })
                .finally(() => {
                    this.ConfigList.ShowLoader = false;
                    this.$saveFilters('SET');
                });
        },

        Eliminar(Id){
            this.$swal(Configs.configEliminar).then((result) => {
                if(result.value) {
                    this.$http.delete('ordenesproduccion/'+Id).then( (res) => {
                        this.$swal(Configs.configEliminarConfirm);
                        this.Lista();
                    })
                        .catch( err => {
                            this.$toast.error(err.response.data.message);
                        });
                }
            });
        },

        getPdfOrdenProduccion(lista){
            let urlPdf = `${this.$http.defaults.baseURL}/getpdfordenproduccion/${lista.IdOrdenProduccion}`;
            window.open(urlPdf,'_blank');
        },

        getPdfFactura(lista){

            let showPDF = false;
            this.$httpFactura.get('/verificaFactura/'+lista.FolioOrden)
                .then( (res) => {
                    if(res.data.status){
                        showPDF = true;
                    }

            }).catch( err => {
                this.$swal(Configs.configFacturaNoDisponible);
            }).finally(() =>{

                if(showPDF){
                    let urlPdf = `${this.$httpFactura.defaults.baseURL}/getfacturapdf/${lista.FolioOrden}`;
                    window.open(urlPdf,'_blank');
                }

            });
        },

        getXmlFactura(lista){

            let urlPdf = `${this.$httpFactura.defaults.baseURL}/getxmlfactura/${lista.FolioOrden}`;
            window.open(urlPdf,'_blank');
        },

        verNotaRemision(lista){
            this.$router.push({name:'viewnotasremisiones',params:{pObjOrdenProduccion:lista}});
        },

        /*updateListaFacturacion() {

            this.ConfigList.ShowLoader = true;
            this.IsLoader.BtnLoad.Icon = this.IsLoader.DefaultSpiner;

            this.$httpFactura.get('/updatefacturacion')
            .then( (res) => {
                if(res.data.status){
                    //this.Lista();
                    console.log('Ejecuta Listado')
                }

            }).catch( err => {
                this.ConfigList.ShowLoader = false;
                this.$swal(Configs.configFacturaNoDisponible);

            }).finally(() =>{
                this.IsLoader.BtnLoad.Icon = this.IsLoader.BtnLoad.Orig;
            });
        },*/

        

        cambioEstatusOrden(item,Accion){

            let valMsg = {};

            switch (Accion){
                case 'EnviaOrden':
                    valMsg = Configs.configEnviarOrdenProd;
                    break;

                case 'AutorizaOrden':
                    valMsg = Configs.configAutorizarOrdenProd;
                    break;

                case 'EnTransito':
                    valMsg = Configs.configReparteOrdenProd;
                    break;

                case 'OrdenEntregada':
                    valMsg = Configs.configEntregaOrdenProd;
                    break;
            }
            this.$swal(valMsg).then((result) => {
                if(result.value) {
                    let request = {
                        IdOrdenProduccion:  item.IdOrdenProduccion,
                        Operacion:      Accion,

                    };

                    this.$http.post('cambioestatusorden', request).then( (res) => {

                        this.$toast.success('Orden Actualizada','Exito','');
                        this.Lista();

                    }).catch((err) => {

                        if (!err.response.data.errors) {
                            this.$toast.error(err.response.data.message);
                        }

                    });
                }
            });

        },

        reviertePrevioEstatus(item) {
            let request = {
                IdOrdenProduccion:  item.IdOrdenProduccion,
            };

            this.$http.post('revierteprocesorden', request).then( (res) => {

                this.$toast.success('Orden Actualizada','Exito','');
                this.Lista();

            }).catch((err) => {

                if (!err.response.data.errors) {
                    this.$toast.error(err.response.data.message);
                }

            });
        },

        cancelarOrden( item ) {
			this.bus.$emit('NewModal_'+EmitEjecuta, item);
		},

        selectUserColor(pIdUser) {

            let obj = this.arrayColorUser.filter( e => parseInt(e.IdUsuario) === parseInt(pIdUser) );

            if (obj.length > 0) {

                return obj[0].color;
                
            }else {

                var colours       =  ['#FF0000', '#008000' ,'#0000FF' ,'#000000' ,'#800000' ,'#808000' , '#008080' ,'#000080' ,'#FF00FF' ,'#800080' ,'#808080','#FF0000','#FA8072' ,'#52BE80','#273746','#7E5109','#3498DB'];     
                const randomMonth = colours[Math.floor(Math.random() * colours.length)];
                
                this.arrayColorUser.push({
                    IdUsuario: pIdUser,
                    color: randomMonth
                })

                return randomMonth
            }

            
        }

    },

    created() {
        if(this.sFiltros===undefined) {
            this.$saveFilters('GET');
        }

        this.bus.$off("Delete_" + EmitEjecuta);
        this.bus.$off("List_" + EmitEjecuta);

        sessionStorage.setItem('IdGeneral','0');
        sessionStorage.setItem('IdSubGeneral','0');
        
    },
    mounted() {
        this.Lista();
        this.bus.$on("Delete_" + EmitEjecuta, (Id) => {
            this.Eliminar(Id);
        });;

        this.bus.$on("List_" + EmitEjecuta, () => {
            this.Lista();
        });


        let usr = JSON.parse(sessionStorage.getItem('user'));
      
        if(parseInt(usr.IdUsuario) == 2){
            this.userClean = true;
        }else if(parseInt(usr.IdUsuario) == 6) {
            this.itsMe = true;
        }
    },
    computed: {},

}
</script>
