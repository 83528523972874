






//************************************************************ AQUI IMPORTAMOS HERRAMIENTAS IMPORTANTES *******************************************************************************
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTreeselect from "@riophae/vue-treeselect";
import vSelect from "vue-select";
import VueSweetalert2 from "vue-sweetalert2";
import Toast from "vue-toastification";
import VCalendar from "v-calendar";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import draggable from "vuedraggable";
import * as VueGoogleMaps from 'vue2-google-maps';
import VueApexCharts from 'vue-apexcharts'
import VueNumeric from "vue-numeric";


//************************************************************ AQUI IMPORTAMOS ARCHIVOS CSS, JAVASCRIPT, BOOSTRAP, FONTS, ICONOS, HELPERS, ETC ****************************************
// ESTILOS PRINCIPALES
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
//import "bootstrap-vue/dist/bootstrap-vue.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-toastification/dist/index.css"


// IMPORT DE COMPONENTES
import '@/import/Index';


// CONFIG
import MyPlugin from "@/config/General.js";
import HttpConfig from "@/config/HttpConfig";
import HttpFacturaConfig from "@/config/HttpFacturaConfig";


// ESTILOS GENERALES
import "@/assets/style/plugin/bootstrap/css/bootstrap.min.css";
// import "@/assets/style/plugin/bootstrap/js/bootstrap.min.js";
import "@/assets/style/plugin/popper/popper.js";
import "@/assets/style/plugin/popper/popper.min.js";
import "@/assets/style/plugin/font-awesome/css/font-awesome.min.css";
import "@/assets/style/plugin/font-awesome/css/all.css";
import "@/assets/style/plugin/fontello/css/secont.css";
import "@/assets/style/plugin/jquery/jquery-3.3.1.min.js";


// ESTILOS PROPIOS
import "@/assets/style/css/main.css";
import "@/assets/style/css/style.css";



//************************************************************ AQUI VA LA BASE URL LOCAL, SERVIDOR DEMO, SERVIDOR PRODUCCIÓN **********************************************************
// CONTROL DEL AXIOS
Vue.config.productionTip = false;
Vue.prototype.bus = new Vue;

let token = sessionStorage.getItem("token_user");
Vue.prototype.$http = HttpConfig(token);
Vue.prototype.$httpFactura = HttpFacturaConfig(token);

//************************************************************ AQUI VA LA CONFIGURACIÓN DE LAS HERRAMIENTAS IMPORTANTES ***************************************************************

// CONFIG GENERAL
Vue.use(MyPlugin, Vue.prototype.$http);

// HELPERS GLOBALES
//Vue.use("VarConfig", VarConfig);

// COMPONENTES GLOBALES DE HERRAMIENTAS IMPORTANTES
library.add(fas);
Vue.component("treeselect", VueTreeselect);
Vue.component("v-select", vSelect);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("draggable", draggable);

// VUE BOOTSTRAP
Vue.use(BootstrapVue);

// OPCIONALMENTE, SE INSTALA EL COMPONENTE DEL ICONO DE BOOTSTRAP VUE
Vue.use(IconsPlugin);

// ALERT SWEET
const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
};

Vue.use(VueSweetalert2, options);

// VUE TOAST
Vue.use(Toast,{
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
});

// VUE CALENDAR
Vue.use(VCalendar);


// VUE MOMENT
Vue.use(require("vue-moment"));

// JQUERY
window.$ = window.jQuery = require("jquery");

// Vue Google Maps
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDaa7OKuZpbRBDa1WFaVNaAwZQXMFPVAHs',
        libraries: 'places',
    },
});

// VUE APEXCHARTS
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

// VUE NUMERIC
Vue.component("VueNumeric", VueNumeric);
Vue.use(VueNumeric);

new Vue({
    router,
    store,
    render: function(h) { return h(App) }
}).$mount("#app")

































//************************************************************ AQUI IMPORTAMOS HERRAMIENTAS IMPORTANTES *******************************************************************************
/*import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueTreeselect from "@riophae/vue-treeselect";
import vSelect from "vue-select";
import VueSweetalert2 from 'vue-sweetalert2';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Toast from "vue-toastification";
import VueNumeric from 'vue-numeric'

//************************************************************ AQUI IMPORTAMOS ARCHIVOS CSS, JAVASCRIPT, BOOSTRAP, FONTS, ICONOS, HELPERS, ETC ****************************************
// ESTILOS PRINCIPALES
import "./assets/style/plugin/bootstrap/css/bootstrap.min.css";
import "./assets/style/plugin/bootstrap/js/bootstrap.bundle.min.js";
//import "./assets/style/plugin/popper/popper.min.js";
import "./assets/style/plugin/font-awesome/css/font-awesome.min.css";
import "./assets/style/plugin/font-awesome/css/all.css";
import "./assets/style/plugin/fontello/css/secont.css";

import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import "vue-select/dist/vue-select.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import "vue-toastification/dist/index.css"

// IMPORT DE COMPONENTES
import '@/import/Index';

// ESTILOS GLOBALES

// CONFIG
// CONFIG
import MyPlugin from '@/helpers/General.js'
import httpInit from "@/config/HttpConfig.js";



// ESTILOS PROPIOS
import './assets/style/css/main.css';


//************************************************************ AQUI VA LA BASE URL LOCAL, SERVIDOR DEMO, SERVIDOR PRODUCCIÓN **********************************************************
// CONTROL DEL AXIOS
Vue.config.productionTip  = false
Vue.prototype.bus         = new Vue;

let token = sessionStorage.getItem('token_user');
Vue.prototype.$http = httpInit(token);


//************************************************************ AQUI VA LA CONFIGURACIÓN DE LAS HERRAMIENTAS IMPORTANTES ***************************************************************

// CONFIG GENERAL
Vue.use(MyPlugin, store, router);


// HELPERS GLOBALES
//Vue.use("VarConfig", VarConfig);

// COMPONENTES GLOBALES DE HERRAMIENTAS IMPORTANTES
library.add(fas)
Vue.component("treeselect", VueTreeselect);
Vue.component("v-select", vSelect);
Vue.component('font-awesome-icon', FontAwesomeIcon);


// ALERT SWEET
const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
};
Vue.use(VueSweetalert2, options);

// VUE TOAST
Vue.use(Toast,{
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
});

// JQUERY
window.$ = window.jQuery = require("jquery");


Vue.use(VueNumeric);



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app') */
