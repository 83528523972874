import DashboardProduccion from "../views/modulos/reportes/dashboardproduccion.vue";
import MenuReportes       from "../views/modulos/reportes/MenuReportes.vue";
import ReporteResumenEntregas from "../views/modulos/reportes/ReporteResumenEntregas.vue";
import ReporteDesgloceOrdenesProduccion from "../views/modulos/reportes/ReporteDesgloceOrdenesProduccion.vue";


const VarRutasReportes = [
    {
        path: "/menureportes",
        name: "menureportes",
        component: MenuReportes,
        props: true,
    },
    {
        path: "/reporteresumenentregas",
        name: "reporteresumenentregas",
        component: ReporteResumenEntregas,
        props: true,
    },
    {
        path: "/reportedesgloceordenesproduccion",
        name: "reportedesgloceordenesproduccion",
        component: ReporteDesgloceOrdenesProduccion,
        props: true,
    },
    /*{
        path: "/reportproductividadsemanales",
        name: "reportproductividadsemanales",
        component: ReportProductividadSemanales,
        props: true,
    },

    {
        path: '/dashboardproduccion',
        name: 'dashboardproduccion',
        component: DashboardProduccion,
        props: true
    },*/
];

export default {
    VarRutasReportes
}