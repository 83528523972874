<template>
    <div>
        <CList :pConfigList="ConfigList" >
            <template slot="bodyForm">
                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        <input :value="preventor" type="hidden">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">
                                    Pedido
                                </a>
                            </li>

                        </ul>

                        <div class="tab-content shadow-sm" id="myTabContent">
                            <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;Cliente&nbsp;</legend>
                                                <div class="form-row mt-1">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="RazonSocial">Folio Pedido </label>
                                                                <input class="form-control" id="RazonSocial" v-model="objPedido.FolioPedido" type="text" placeholder="" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <BuscadorCliente ref="buscadorCliente" @input="getCliente()" v-model="objPedido.IdCliente" :p-disabled="objPedido.Estatus != 'Pendiente' && objPedido.Estatus != ''">
                                                                    <CValidation v-if="this.errorvalidacion.IdCliente" :Mensaje="'*' + errorvalidacion.IdCliente[0]"/>
                                                                </BuscadorCliente>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row ">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="RazonSocial">Razón Social </label>
                                                                <input class="form-control" id="RazonSocial" v-model="objCliente.RazonSocial" type="text" placeholder="" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="Rfc">RFC </label>
                                                                <input class="form-control" id="Rfc" v-model="objCliente.Rfc" type="text" placeholder="" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="Direccion">Dirección </label>
                                                                <input class="form-control" id="Direccion" v-model="objCliente.Direccion" type="text" placeholder="" disabled/>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="Contacto">Contacto </label>
                                                                <input class="form-control" id="Contacto" v-model="objCliente.Contacto" type="text" placeholder="" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="Telefono">Telefono </label>
                                                                <input class="form-control" id="Telefono" v-model="objCliente.Telefono" type="text" placeholder="" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="Correo">Correo </label>
                                                                <input class="form-control" id="Correo" v-model="objCliente.Correo" type="text" placeholder="" disabled/>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" v-if="parseInt(objPedido.IdCliente) > 0">
                                                                <label for="Contacto">Compra con IVA </label>
                                                                <input class="form-control" id="Contacto" :value="[objPedido.ConIva ? 'SI' : 'NO']" type="text" v-bind:style="colorInput(objPedido.ConIva)" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <!-- CLIENTE SUCURSAL -->
                                                <hr>
                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <div class="form-group">
                                                                    <label for="">Sucursal</label>
                                                                    <select id="ClienteSucursal" class="form-control form-select" @change="setClienteSucursal()" v-model="objPedido.IdClienteSucursal" :disabled="objPedido.Estatus != 'Pendiente' && objPedido.Estatus != ''">
                                                                        <option :value="0">--Seleccionar--</option>
                                                                        <option v-for="(item, index) in ListClienteSucursales" :key="index" :value="item.IdClienteSucursal">
                                                                            {{ item.Nombre }}
                                                                        </option>
                                                                    </select>
                                                                    <CValidation v-if="this.errorvalidacion.IdClienteSucursal" :Mensaje="'*' + errorvalidacion.IdClienteSucursal[0]"/>

                                                                </div>
                                                            </div>

                                                            <!--<div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <div class="form-group">
                                                                    <label for="OrdenProdCliente">No. Orden de produccion del Cliente <small>(opcional)</small> </label>
                                                                    <input class="form-control" id="OrdenProdCliente" v-model="objPedido.OrdenProdCliente" type="text" placeholder="Escribe el numero de orden proporcionado por el cliente" :disabled="(parseInt(objPedido.IsNoOrdenGeneral) > 0)"/>
                                                                </div>
                                                            </div>-->
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row ">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="RazonSocial">Telefono </label>
                                                                <input class="form-control" id="RazonSocial" v-model="objClienteSucursal.Telefono" type="text" placeholder="" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label for="Direccion">Dirección </label>
                                                                <textarea class="form-control" rows="1" v-model="objClienteSucursal.Direccion" disabled></textarea>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                            </fieldset>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;General de la Orden &nbsp;</legend>
                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div class="form-row">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                                <div class="form-group form-row" v-if="objPedido.IdPedido > 0">
                                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                        <label for="Contacto">Fecha Levantamiento <span class="text-danger">*</span></label>
                                                                        <input class="form-control" id="FechaLevantamiento" :value="$getCleanDate(objPedido.FechaLevantamiento,false)" type="text" placeholder="" disabled/>
                                                                    </div>

                                                                    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                                       </div>
                                                                </div>

                                                                <div class="form-group form-row">
                                                                    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                                        <!--
                                                                        <label for="Observaciones">Observaciones </label>
                                                                        <textarea id="Observaciones" placeholder="Ingrese Observaciones..." rows="1" wrap="soft" class="form-control" v-model="objPedido.Observaciones"></textarea>
                                                                    -->
                                                                    </div>
                                                                </div>

                                                                <!--<div class="form-group form-row mt-2">
                                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                        <div class="form-check">
                                                                            <input v-model="objPedido.ConDistribucion"  type="checkbox" class="form-check-input" id="exampleCheck1">
                                                                            <label class="form-check-label" for="exampleCheck1">Distribuir en Sucursales</label>
                                                                        </div>
                                                                    </div>
                                                                </div>-->

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-12 mt-1">
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;Detalle del Pedido &nbsp;</legend>
                                                <div class="row">
                                                    <div class="col-md-12 jutify-content-end">
                                                        <button v-if="objPedido.Estatus == 'Pendiente' || objPedido.Estatus == ''" type="button" @click="buscaProductos()" class="btn btn-primary btn-sm float-right mt-1 mb-1">
                                                            <i class="fas fa-plus-circle"></i> Agregar
                                                        </button>
                                                    </div>
                                                </div>
                                                <CTablita :pConfigList="ConfigListDetalle">
                                                    <template slot="header">
                                                        <th>Part.</th>
                                                        <th>Descripcion</th>
                                                        <th>Cantidad</th>
                                                        <th>Precio Unitario</th>
                                                        <th>IVA</th>
                                                        <th>Importe</th>
                                                        <th class="text-center">Acciones</th>

                                                    </template>
                                                    <template slot="body">
                                                        <tr  v-for="(item,index) in objPedidoDetalle"  :key="index">

                                                            <td>{{ index+1 }}</td>
                                                            <td>
                                                               <textarea v-model="item.Descripcion"
                                                                         class="form-control form-control-sm"
                                                                         rows="2" readonly>
                                                               </textarea>
                                                            </td>
                                                            <td>
                                                                <vue-numeric 
                                                                    class="form-control form-control-sm" 
                                                                    v-model="item.Cantidad"
                                                                    placeholder="cantidad" 
                                                                    @input="hacerCalculos(index, item)"
                                                                    currency="" 
                                                                    separator="," 
                                                                    v-bind:min="1"
                                                                    :precision="0" 
                                                                    style="width:90px"
                                                                    :disabled="objPedido.Estatus != 'Pendiente' && objPedido.Estatus != ''"
                                                                    :empty-value="0"
                                                                     />
                                                    
                                                            </td>
                                                            <td>
                                                                <vue-numeric 
                                                                    class="form-control form-control-sm" 
                                                                    v-model="item.PrecioUnitario"
                                                                    @input="hacerCalculos(index, item)"
                                                                    placeholder="$0.00" 
                                                                    currency="$" 
                                                                    separator="," 
                                                                    :precision="2" 
                                                                    style="width:120px"
                                                                    :empty-value="0"
                                                                    :disabled="objPedido.Estatus != 'Pendiente' && objPedido.Estatus != ''"
                                                                     />
                                                            </td>
                                                            <td>
                                                                <span v-if="parseInt(item.ConIva) > 0">SI</span>
                                                                <span v-else>NO</span>
                                                            </td>

                                                            <td>
                                                                <b>
                                                                    <vue-numeric 
                                                                    class="form-control form-control-sm" 
                                                                    v-model="item.Importe"
                                                                    currency="$" 
                                                                    separator="," 
                                                                    :precision="2" 
                                                                    style="width:120px"
                                                                    :empty-value="0"
                                                                    :read-only="true"
                                                                     />
                                                                </b>
                                                            </td>
                                                            

                                                            <td class="text-center">
                                                                <CBtnAccion :pShowBtnEdit="false" :pShowBtnDelete="false" :pIsModal="true" :pId="item.IdClienteSucursal" :pEmitSeccion="ConfigListDetalle.EmitSeccion" :segurity="segurity">
                                                                    <template slot="btnaccion">
                                                                        <button v-if="objPedido.Estatus == 'Pendiente' || objPedido.Estatus == ''" type="button" v-b-tooltip.hover.Top title="Eliminar" class="btn btn-danger btn-icon mr-1" @click="eliminarDetalle(index)">
                                                                            <i class="fas fa-trash-alt"></i>
                                                                        </button>
                                                                    </template>
                                                                </CBtnAccion>
                                                            </td>

                                                        </tr>
                                                        <CSinRegistros :pContIF="objPedidoDetalle.length" :pColspan="7" />
                                                    </template>
                                                </CTablita>

                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div class="form-row">
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">

                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <div class="form-group form-row">
                                                                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                                        <label>Subtotal:</label>
                                                                    </div>
                                                                    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                                       
                                                                        <vue-numeric 
                                                                            class="form-control form-control-sm" 
                                                                            v-model="objPedido.Subtotal"
                                                                            placeholder="$0.00" 
                                                                            currency="$" 
                                                                            separator="," 
                                                                            :precision="2" 
                                                                            :disabled="true"
                                                                            :empty-value="0.00"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div class="form-group form-row mt-4">
                                                                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">

                                                                        <!--<div class="form-check">
                                                                            <input readonly v-model="objPedido.ConIva"  @change="hacerCalculos" type="checkbox" class="form-check-input" id="exampleCheck1">
                                                                            <label class="form-check-label" for="exampleCheck1">IVA:</label>
                                                                        </div>-->
                                                                        <label>IVA:</label>
                                                                    </div>
                                                                    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                        
                                                                        <vue-numeric 
                                                                            class="form-control form-control-sm" 
                                                                            v-model="objPedido.Iva"
                                                                            placeholder="$0.00" 
                                                                            currency="$" 
                                                                            separator="," 
                                                                            :precision="2" 
                                                                            :disabled="true"
                                                                            :empty-value="0.00"
                                                                        />
                                                                    </div>
                                                                </div>


                                                                <div class="form-group form-row mt-4">
                                                                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                                        <label>Total:</label>
                                                                    </div>
                                                                    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                                        <vue-numeric 
                                                                            class="form-control form-control-sm" 
                                                                            v-model="objPedido.Total"
                                                                            placeholder="$0.00" 
                                                                            currency="$" 
                                                                            separator="," 
                                                                            :precision="2" 
                                                                            :disabled="true"
                                                                            :empty-value="0.00"
                                                                        />

                                                                        
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </fieldset>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <CBtnSave :poBtnSave="oBtnSave" />
                        </div>
                    </template>
                </CLoader>
            </template>
        </CList>

        <CModal :pConfigModal="ConfigModalProductos" :poBtnSave="poBtnSaveProductos">
            <template slot="Form">
                <ClistProductos :poBtnSave="poBtnSaveProductos"/>
            </template>
        </CModal>


    </div>
</template>
<script>
import moment from "moment"
import CList from "@/components/CList.vue";
import CValidation from "@/components/CValidation.vue";
import CLoader from "@/components/CLoader.vue";
import Template from "@/views/template/Template.vue";
import CBtnSave from "@/components/CBtnSave.vue";
import BuscadorCliente from "@/components/BuscadorCliente.vue";
import BuscadorClienteSucursal from "@/components/BuscadorClienteSucursal.vue";
import CTablita from "@/components/CTablita.vue";
import CBtnAccion from "@/components/CBtnAccion.vue";
import CSinRegistros from "@/components/CSinRegistros.vue";
import CModal from "@/components/CModal.vue";
import ClistProductos from "@/components/CListProductos.vue";



const EmitEjecuta = 'seccionFormProduccion';
const EmitEjecutaDetalle = 'seccionFormDetalle';
const EmitEjecutaProductos = 'seccionSelectProductos';


export default {
    name: "FormPedidos",
    props: ["Id"],
    components: {
        Template,
        CList,
        CValidation,
        CLoader,
        CBtnSave,
        BuscadorCliente,
        BuscadorClienteSucursal,
        CTablita,
        CBtnAccion,
        CSinRegistros,
        CModal,
        ClistProductos
    },
    data() {
        return {
            errorvalidacion:[],
            totalProducto:[],
            segurity:{Add:1,Edit:1,Delete:1},
            ListClienteSucursales:{},
            masks: {
                input: "YYYY-MM-DD",
            },

            ConfigList: {
                ShowTitleFirst:    false,
                Title:             "Detalle Pedidos",
                ShowLoader:        true,
                IsModal:           false,
                BtnNewShow:        false,
                BtnReturnShow:     true,
                TypeBody:          "Form",
                ShowFiltros:       false,
                ShowFiltrosInline: true,
                ShowTitleInline:   false,
                ShowPaginador:     false,
            },

            ConfigLoad: {
                ShowLoader: true,
                ClassLoad:  false,
            },

            oBtnSave: {
                toast:         0,
                IsModal:       false,
                ShowBtnSave:   true,
                ShowBtnCancel: true,
                DisableBtn:    false,
                EmitSeccion:   EmitEjecuta,
                ReturnRoute:   'pedidos'
            },

            BlockerDates:{
                BlockFecha:true,
                BlockHoraI:false,
                BlockHoraF:false,
                FechaActual:'',
                FechaAyer:'',
                isPast: false
            },

            objPedido:{
                IdPedido: 0,
                IdCliente: 0,
                IdClienteSucursal: 0,
                FolioPedido: '',
                Subtotal: 0,
                Iva: 0,
                Total: 0,
                Observaciones: '',
                ConIva: false,
                Estatus:'',
                OrdenProdCliente: '',
                IsNoOrdenGeneral: 0,
            },
            objPedidoDetalle:[],

            objCliente:{
                NombreComercial: '',
                RazonSocial: '',
                Rfc: '',
                Contacto: '',
                Telefono: '',
                Correo: '',
                Direccion:'',
                Calle: '',
                NoInt: '',
                NoExt: '',
                Colonia: '',
                Cp: '',
                Cruzamiento1: '',
                Cruzamiento2: '',
                Referencias: '',
                IdEstado: '',
                IdMunicipio: '',
                CompraConIva: 0,
            },

            objClienteSucursal:{
                Direccion : "",
                EsMatriz: 0,
                IdCliente: 0,
                IdClienteSucursal: 0,
                Nombre: '',
                Telefono: '',
            },

            ConfigListDetalle:{
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecutaDetalle,
            },

            ConfigModalProductos: {
                ModalTitle:  "Productos",
                ModalNameId: 'ModalForm',
                EmitSeccion: EmitEjecutaProductos,
                ModalSize:   'lg',
                ShowFooter: true,
            },
            poBtnSaveProductos:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecutaProductos,
                ShowBtnSave: true,
                IdObjeto: 0,
            },

        }
    },
    methods: {
        getCliente(withSet = false) {
            if(this.objPedido.IdCliente != null && this.objPedido.IdCliente != undefined) 
            {
                this.$http.get( `/clientes/${this.objPedido.IdCliente}` )
                .then( res => {
                    const cliente = res.data.data.Cliente;
                    this.objCliente = cliente;

                    if(withSet){
                        this.setCliente(this.objCliente);
                    }
                    
                    
                    // SE OBTIENE LA CONFIGURACION DE IVA DEL CLIENTE, 
                    // ESTO DETERMINA SI EL PEDIDO LLEVA O NO IVA, DE LOS PRODUCTOS QUE SI TENGAN IVA
                    if (parseInt(this.objCliente.CompraConIva) > 0) {
                        this.objPedido.ConIva = true;
                    
                    }else {
                        this.objPedido.ConIva = false;
                    }

                }).catch( err => {

                    this.limpiaCliente();

                }).finally(() => {
                    if(this.objPedidoDetalle.length > 0){
                        this.hacerCalculos();
                    }
                    this.getClienteSucursal();
                });
            }else {
                this.limpiaCliente();
                
            }
            
        },

        setCliente( cliente = {} ) {

            const buscadorCliente = this.$refs.buscadorCliente;

            buscadorCliente.clientes.push({
                label: `${cliente.NombreComercial}`,
                key: cliente.IdCliente,
                detalle: {
                    direccion: Contacto,
                },
            });

        },

        getClienteSucursal() {
            if(this.objPedido.IdCliente > 0){
                this.$http.get('sucursalcliente',{
                    params:{
                        IdCliente: this.objPedido.IdCliente,
                        isSimple:1
                    }
                })
                    .then( res => {
                        this.ListClienteSucursales = res.data.data;
                    }).catch( err => {

                }).finally(() => {
                    this.setClienteSucursal();
                    
                });
            }else{
                this.ListClienteSucursales = [];
            }
        },



        setClienteSucursal(){

            if(parseInt(this.objPedido.IdClienteSucursal) > 0 && this.ListClienteSucursales.length > 0 && this.ListClienteSucursales != ''){
                this.objClienteSucursal = {};
                let IdSuc = this.objPedido.IdClienteSucursal;
                const index = this.ListClienteSucursales.findIndex(item => parseInt(item.IdClienteSucursal) === IdSuc);

                if(index !== -1) {
                    this.objClienteSucursal = this.ListClienteSucursales[index];
                }
            }

        },

        limpiaPedido(conDetalle = true){
            this.BlockerDates ={
                BlockFecha:true,
                BlockHoraI:false,
                BlockHoraF:false,
                FechaActual:'',
                FechaAyer:'',
                isPast: false
            };

            this.objPedido = {
                IdPedido: 0,
                IdCliente: 0,
                IdClienteSucursal: 0,
                FolioPedido: '',
                FechaLevantamiento: '',
                FechaEntrega: '',
                Subtotal: 0,
                Iva: 0,
                Total: 0,
                Observaciones: '',
                ConIva: false,
                Estatus:'',
                OrdenProdCliente: '',
                IsNoOrdenGeneral: 0,
            };

            if(conDetalle){
                this.objPedidoDetalle = [];
            }
            

        },

        limpiaCliente(){
            this.objCliente = {
                NombreComercial: '',
                RazonSocial: '',
                Rfc: '',
                Contacto: '',
                Telefono: '',
                Correo: '',
                Direccion:'',
                Calle: '',
                NoInt: '',
                NoExt: '',
                Colonia: '',
                Cp: '',
                Cruzamiento1: '',
                Cruzamiento2: '',
                Referencias: '',
                IdEstado: '',
                IdMunicipio: '',
                CompraConIva: 0,
            }

            this.objClienteSucursal = {
                Direccion : "",
                    EsMatriz: 0,
                    IdCliente: 0,
                    IdClienteSucursal: 0,
                    Nombre: '',
                    Telefono: '',
            };

            this.ListClienteSucursales = [];
            
        },

        Recuperar() {
            this.$http.get("pedidos/"+this.objPedido.IdPedido)
                .then((res) => {

                    let result = res.data.data;
                    result.cliente.IdCliente            = parseInt(result.cliente.IdCliente);
                    this.objPedido                      = result;
                    this.objPedido.ConIva               = parseInt(this.objPedido.ConIva) > 0 ? true : false;
                    this.objCliente                     = this.objPedido.cliente;
                    this.getCliente(true);
                    this.objPedidoDetalle               = this.objPedido.detalles;

                    if(this.objPedido.Estatus != 'Pendiente' && this.objPedido.Estatus != ''){
                        this.oBtnSave.ShowBtnSave = false;
                    }


                }).finally(() => {
                this.hacerCalculos();
                this.ConfigLoad.ShowLoader = false;

            });
        },

        Guardar(){
            let Fecha1 = '';
            if(this.objPedido.FechaEntrega !=''){
                Fecha1 = moment(this.objPedido.FechaEntrega).format('YYYY-MM-DD');
            }
            this.objPedido.FechaEntrega = Fecha1;

            const bodyParams = {
                IdPedido: this.objPedido.IdPedido,
                IdCliente: this.objPedido.IdCliente,
                IdClienteSucursal: this.objPedido.IdClienteSucursal,
                Subtotal: this.objPedido.Subtotal,
                ConIva: this.objPedido.ConIva,
                Iva: this.objPedido.Iva,
                Total: this.objPedido.Total,
                OrdenProdCliente: this.objPedido.OrdenProdCliente,
                IsNoOrdenGeneral: this.objPedido.IsNoOrdenGeneral,

                pedidoDetalle: JSON.stringify(this.objPedidoDetalle),
            };

            this.$http.post( '/pedidos', bodyParams )
                .then( res  => {

                    this.EjecutaConExito(res);


                })
                .catch( err => {
                    this.EjecutaConError(err);
                });
        },

        EjecutaConExito(res) {
            this.oBtnSave.DisableBtn = false;
            this.bus.$emit('RunAlerts_'+this.ConfigList.EmitSeccion,1);
            this.Regresar();
        },

        EjecutaConError(err) {
            this.oBtnSave.DisableBtn = false;

            if (err.response.data.errors) {
                this.errorvalidacion = err.response.data.errors;
                this.oBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },

        eliminarDetalle( index ) {

            this.objPedidoDetalle.splice( index, 1 );
            this.hacerCalculos();

        },

        hacerCalculos() {
            const tazaIva           = 0.16;
            let subtotal            = 0.00;
            let iva                 = 0.00;
            let total               = 0;
            let totalIvaProductos   = 0.00;
            let numProductos        = [];
            this.totalProducto      = [];
            

            this.objPedidoDetalle.forEach( (element) => {
                let pUnitario = 0;
                let cantidad = 0;
                let importeFinal = 0;

                // EVALUAR SI EL MONTO ES NAN O MAYOR A 0
                if(!isNaN(element.PrecioUnitario) && parseFloat(element.PrecioUnitario) > 0){
                    pUnitario = parseFloat(element.PrecioUnitario);
                }

                // EVALUAR SI EL MONTO ES NAN O MAYOR A 0
                if(!isNaN(element.Cantidad) && parseFloat(element.Cantidad) > 0){
                    cantidad = parseFloat(element.Cantidad);
                }

                importeFinal = (pUnitario * cantidad)
                element.Importe =  importeFinal.toFixed(2);
                
                // VERIFICAR Y CALCULAR EL VALOR DEL IVA DEL TOTAL DE PRODUCTO
                if(parseInt(element.ConIva) > 0){
                    totalIvaProductos += (parseFloat(importeFinal) * tazaIva)
                }

                subtotal += parseFloat(element.Importe);

                 

                // CONTADOR DE PRODUCTOS PARA DISTIBUCION
                numProductos.push({
                    IdProducto: parseInt(element.IdProducto),
                    TotalProducto: parseFloat(element.Cantidad)
                });

            });

            this.totalProducto = numProductos; //CONTADOR DE PRODUCTOS PARA DISTIBUCION
            this.objPedido.Subtotal = subtotal.toFixed(2);

            // EVALUAR SI SE REQUIERE O NO EL IVA DEL SUBTOTAL
            if(this.objPedido.ConIva) {
                /*iva     = (subtotal * 0.16);
                iva     = isNaN(iva) ? 0 : iva;*/

                total   = (totalIvaProductos + subtotal)
                total   = isNaN(total) ? 0 : total;

                this.objPedido.Iva     = totalIvaProductos.toFixed(2);
                this.objPedido.Total   = total.toFixed(2);

            }else {
                this.objPedido.Iva     = iva.toFixed(2);
                this.objPedido.Total   = subtotal.toFixed(2);
            }
        },

        buscaProductos() {
            if(parseInt(this.objPedido.IdCliente) > 0){
                this.bus.$emit('NewModal_'+EmitEjecutaProductos,this.objPedido.IdCliente);
            }else {
                this.$toast.warning('Necesita Seleccionar un Cliente Primero','Seleccione Cliente','');
            }
            
        },

        setValorProducto(obj)  {
            let arr = this.objPedidoDetalle.filter(function(item,index){
                if(item.IdProducto == obj.IdProducto){
                    return item;
                } else {
                    return '';
                }
            });

            if(arr[0]) {
                console.log(arr[0]);
                this.$toast.warning('El producto ya ha sigo agregado anteriormente','Producto Repetido','');

            }else {
                this.objPedidoDetalle.push({
                    IdProducto: obj.IdProducto,
                    Descripcion: obj.Producto,
                    Cantidad: 1,
                    PrecioUnitario: obj.PrecioBase,
                    Importe: obj.PrecioBase,
                    ConIva: obj.ConIva
                });
            }

            this.hacerCalculos();

        },

        Regresar() {
            this.$router.push({name:'pedidos',params:{}});
        },

        fechaMinima() {
            // OBTENEMOS LA FECHA ACTUAL

            let FechaActual = moment(new Date());
            FechaActual = FechaActual.format('YYYY-MM-DD');

            this.BlockerDates.FechaActual = FechaActual;
            //console.log(this.BlockerDates.FechaActual);

            // OBTENEMOS LA FECHA DE AYER BASADA EN LA FECHA ACTUAL
            let FechaA = moment(new Date());
            FechaA = FechaA.subtract(1,"days");
            FechaA = FechaA.format('YYYY-MM-DD');


            this.BlockerDates.FechaAyer=FechaA;

            if(parseInt(this.objPedido.IdPedido) > 0 ){
                // SI LA FECHA INICIAL DEL SERVICIO ESTA ENTRE EL RANGO DE FECHAS DE AYER Y HOY, NO SE BLOQUEA NADA
                if (this.objPedido.FechaEntrega >= this.BlockerDates.FechaAyer && this.objPedido.FechaEntrega <= this.BlockerDates.FechaActual ) {
                    this.BlockerDates.BlockFecha = null;
                    this.BlockerDates.isPast     = (this.objPedido.FechaEntrega < this.BlockerDates.FechaActual) ? true: false;

                }else{
                    //
                    if(this.objPedido.FechaEntrega > this.BlockerDates.FechaActual ) {
                        this.BlockerDates.BlockFecha = false;
                    }else {
                        this.BlockerDates.BlockFecha = true;
                    }
                }

                if(this.BlockerDates.BlockFecha) {
                    return null

                } else{
                    let fecha = '';
                    if(this.BlockerDates.isPast) {
                        fecha = moment(this.BlockerDates.FechaAyer).add(1,'day').format('YYYY-MM-DD');
                        fecha = new Date(fecha);

                    }else {
                        fecha = moment(new Date()).add(1,'day').format('YYYY-MM-DD');
                        fecha = new Date(fecha);
                    }


                    return fecha;
                }

            }else {
                let fecha = '';
                fecha = moment(new Date()).add(1,'day').format('YYYY-MM-DD');
                fecha = new Date(fecha);
                return fecha;
            }

        },

        colorInput(type) {
            let color  = 'color: #fff; background-color: #6c757d;';

            if(type){
                 // VERDE
                color = 'color: #1aae6f; background-color: #afe2cc; font-weight:bold; border-color:#1aae6f;';
            }else {
                // ROJO
                color  = 'color: #f80031; background-color: #fcacbc; font-weight:bold; border-color:#f80031;';
            }
            return color

        },



    },
    computed: {
        preventor: function(){
            if(this.objPedido.IdCliente == null){
                this.limpiaCliente();
                let detalle = this.objPedidoDetalle.length > 0 ? false : true;
                this.limpiaPedido(detalle);
                
            }
        }
    },
    created() {
        this.bus.$off("Save_"+EmitEjecuta);
        this.bus.$off('pAsignarProducto');
    },
    mounted() {
        this.limpiaPedido();
        this.limpiaCliente();

        if (this.Id > 0) {
            this.objPedido.IdPedido = this.Id;
            this.Recuperar();

        } else {
            this.ConfigLoad.ShowLoader = false;
        }

        this.bus.$on("Save_"+EmitEjecuta, () => {
            this.Guardar();
        });

        this.bus.$on("EmitReturn", () => {
            this.Regresar();
        });

        this.bus.$on('pAsignarProducto',(obj)=>
        {
            this.setValorProducto(obj);
        });

    }

}
</script>
