const MenuInicio = {
    Nombre: 'Inicio',
    Icono: 'fas fa-home-lg-alt',
    Permiso: 'CLAVEINICIO',
    Router: 'inicio',
    Parametros: {sFiltros:'NO'},
    TipoBoton: 0,
    EsDeRoot: 0,
    SubMenus: []
};

const MenuReportes = {
    Nombre: 'Reportes',
    Icono: 'fas fa-books',
    Permiso: 'CLAVEREPORTES',
    Router: 'menureportes',
    Parametros: {sFiltros:'NO'},
    TipoBoton: 0,
    EsDeRoot: 0,
    SubMenus: []
};

const MenuMiEmpresa = {
    Nombre: 'Empresa',
    Icono: 'fas fa-building',
    Permiso: 'CLAVEEMPRESA',
    Router: '',
    Parametros: {sFiltros:'NO'},
    TipoBoton: 0,
    EsDeRoot: 0,
    SubMenus: [
        {
            Nombre: 'Mi Empresa',
            Icono: "",
            Permiso: 'CLAVEMIEMPRESA',
            Router: 'empresa',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            Nombre: 'Empleados',
            Icono: "",
            Permiso: 'CLAVEEMPLEADOS',
            Router: 'empleados',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        }
    ]
};

const MenuCrm = {
    Nombre: "CRM",
    Icono: "fas fa-chart-network",
    Permiso: 'CLAVECRM',
    Router: "",
    Parametros: {sFiltros:'NO'},
    TipoBoton: 0,
    EsDeRoot: 0,
    SubMenus: [
        {
            Nombre: "Clientes",
            Permiso: 'CLAVECLIENTES',
            Router: "clientesActivos",
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: [],
        },
        {
            Nombre: "Reasignación",
            Permiso: 'CLAVECLIENTES',
            Router: "reasignacionclientes",
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: [],
        },
    ],
};

const MenuProduccion = {

    Nombre: 'Producción',
    //Icono: 'fas fa-digging',
    Icono: 'fas fa-user-hard-hat',
    Permiso: 'CLAVEPRODUCCION',
    Router: '',
    Parametros: {sFiltros:'NO'},
    TipoBoton: 0,
    EsDeRoot: 0,
    SubMenus: [
        {
            Nombre: 'Pedidos',
            Icono: "",
            Permiso: 'CLAVEPEDIDOS',
            Router: 'pedidos',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            Nombre: 'Ordenes de Produccion',
            Icono: "",
            Permiso: 'CLAVEORDENPRODUCCION',
            Router: 'ordenesproduccion',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            Nombre: 'Recolecciones',
            Icono: "",
            Permiso: 'CLAVERECOLECCION',
            Router: 'recolecciones',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            Nombre: 'Dashboard',
            Icono: "",
            Permiso: 'CLAVEDASHPRODUCCION',
            Router: 'dashboardproduccion',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },


    ]
};

const MenuConfig = {
    Nombre: 'Configuración',
    Icono: 'fas fa-cog',
    Permiso: 'CLAVECONFIGURACION',
    Router: '',
    Parametros: {sFiltros:'NO'},
    TipoBoton: 0,
    EsDeRoot: 0,
    SubMenus: [

        {
            Nombre: 'Vehiculos',
            Icono: "",
            Permiso: 'CLAVEVEHICULOS',
            Router: 'vehiculos',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            Nombre: 'Productos',
            Icono: "",
            Permiso: 'CLAVEPRODUCTOS',
            Router: 'productos',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            Nombre: 'Proveedores',
            Icono: "",
            Permiso: 'CLAVEPROVEEDORES',
            Router: 'proveedores',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            Nombre: 'Tipo Negocios',
            Icono: "",
            Permiso: 'CLAVENEGOCIOS',
            Router: 'negocios',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            Nombre: 'Perfiles',
            Icono: "",
            Permiso: 'CLAVEPERFILES',
            Router: 'perfiles',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            Nombre: "Puestos",
            Icono: "",
            Permiso: 'CLAVEPUESTOS',
            Router: "puestos",
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: [],
        },
        {
            Nombre: 'Menus',
            Icono: "",
            Permiso: 'CLAVEMENUS',
            Router: 'paneles',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 1,
            Apartados: []
        },
        {
            Nombre: 'Permisos',
            Icono: "",
            Permiso: 'CLAVEPERMISOS',
            Router: 'permisos',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 1,
            Apartados: []
        },
        {
            Nombre: "Correo Electrónico",
            Icono: "",
            Permiso: 'CLAVECORREO',
            Router: "correo",
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: [],
        },
    ]
};

const MenuFacturacion = {
    Nombre: 'Facturación',
    Icono: 'fas fa-file-invoice-dollar',
    Permiso: 'CLAVEMENUFACTURACION',
    Router: '',
    Parametros: {sFiltros:'NO'},
    TipoBoton: 0,
    EsDeRoot: 0,
    SubMenus: [
        {
            Nombre: 'Facturas',
            Icono: "",
            Permiso: 'CLAVEFACTURA',
            Router: 'facturas',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            Nombre: 'Catalogos Conf.',
            Icono: "",
            Permiso: 'CLAVECATALOGOSCONFIGFACTURA',
            Router: 'panelSubMenus',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 1,
            EsDeRoot: 0,
            Apartados: [
                {
                    Nombre: "Proveedor Factuación",
                    Icono: 'fas fa-user-tag',
                    Permiso: 'CLAVEPROVEEDORFACTURA',
                    Router: "proveedorfacturacion",
                    Parametros: {sFiltros:'NO'},
                    TipoBoton: 0,
                    EsDeRoot: 0,
                    Apartados: [],
                },
                {
                    Nombre: "Llaves Timbrado",
                    Icono: 'fas fa-key',
                    Permiso: 'CLAVELLAVETIMBRADO',
                    Router: "llavestimbrado",
                    Parametros: {sFiltros:'NO'},
                    TipoBoton: 0,
                    EsDeRoot: 0,
                    Apartados: [],
                },
                {
                    Nombre: "Bancos",
                    Icono: 'fas fa-landmark-alt',
                    Permiso: 'CLAVEBANCOS',
                    Router: "bancos",
                    Parametros: {sFiltros:'NO'},
                    TipoBoton: 0,
                    EsDeRoot: 0,
                    Apartados: [],
                },
                {
                    Nombre: "Productos y Servicios",
                    Icono: 'fas fa-people-carry',
                    Permiso: 'CLAVEPRODSERVICIOS',
                    Router: "productoservicios",
                    Parametros: {sFiltros:'NO'},
                    TipoBoton: 0,
                    EsDeRoot: 0,
                    Apartados: [],
                },
                {
                    Nombre: "Forma de Pago",
                    Icono: 'fas fa-comments-dollar',
                    Permiso: 'CLAVEFORMAPAGO',
                    Router: "formapago",
                    Parametros: {sFiltros:'NO'},
                    TipoBoton: 0,
                    EsDeRoot: 0,
                    Apartados: [],
                },
                {
                    Nombre: "Metodo de Pago",
                    Icono: 'fas fa-envelope-open-dollar',
                    Permiso: 'CLAVEMETODOPAGO',
                    Router: "metodopago",
                    Parametros: {sFiltros:'NO'},
                    TipoBoton: 0,
                    EsDeRoot: 0,
                    Apartados: [],
                },
                {
                    Nombre: "Monedas",
                    Icono: 'fas fa-coins',
                    Permiso: 'CLAVEMONEDA',
                    Router: "moneda",
                    Parametros: {sFiltros:'NO'},
                    TipoBoton: 0,
                    EsDeRoot: 0,
                    Apartados: [],
                },
                {
                    Nombre: "Objeto de Impuesto",
                    Icono: 'far fa-hands-usd',
                    Permiso: 'CLAVEOBJETOIMPUESTO',
                    Router: "objetoimpuesto",
                    Parametros: {sFiltros:'NO'},
                    TipoBoton: 0,
                    EsDeRoot: 0,
                    Apartados: [],
                },
                {
                    Nombre: "Regimenes Fiscales",
                    Icono: 'fas fa-gavel',
                    Permiso: 'CLAVEREGIMENFISCAL',
                    Router: "regimenfiscal",
                    Parametros: {sFiltros:'NO'},
                    TipoBoton: 0,
                    EsDeRoot: 0,
                    Apartados: [],
                },
                {
                    Nombre: "Tipo Relación CFDI",
                    Icono: 'far fa-retweet-alt',
                    Permiso: 'CLAVETIPORELACIONCFDI',
                    Router: "tiporelacioncfdi",
                    Parametros: {sFiltros:'NO'},
                    TipoBoton: 0,
                    EsDeRoot: 0,
                    Apartados: [],
                },
                {
                    Nombre: "Unidad de Medida",
                    Icono: 'far fa-balance-scale-right',
                    Permiso: 'CLAVEUNIDADMEDIDA',
                    Router: "unidadmedida",
                    Parametros: {sFiltros:'NO'},
                    TipoBoton: 0,
                    EsDeRoot: 0,
                    Apartados: [],
                },
                {
                    Nombre: "Uso de CFDI",
                    Icono: 'fas fa-money-check-alt',
                    Permiso: 'CLAVEUSOCFDI',
                    Router: "usocfdi",
                    Parametros: {sFiltros:'NO'},
                    TipoBoton: 0,
                    EsDeRoot: 0,
                    Apartados: [],
                },
            ]
        },
    ]
}

const ConfigMenus = [
    MenuInicio,
    MenuMiEmpresa,
    MenuCrm,
    MenuProduccion,
    MenuFacturacion,
    MenuReportes,
    MenuConfig

];

export default {
    ConfigMenus,
}
