<template>
    <div>
        <CList :pConfigList="ConfigList" >

            <template slot="accHeader">

                <form onsubmit="return false" class="form-inline float-right">
                    <div class="form-group">
                        <select id="IdSucursal" v-model="IdSucursalGlob" v-show="ShowCmbSucursales" @change="blockPrint()" class="form-control form-select mr-1" :disabled="IsLoader.BloqCmb1">
                            <option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
                                {{ item.Nombre }}
                            </option>
                        </select>

                        <div class="form-group mx-sm-2">
                            <!--- <v-date-picker v-model="objFiltroFechasGlob" :masks="masks" @input="blockPrint()" :popover="{ visibility: 'focus' }" :disabled-dates='{ weekdays: [1,2,3,4,5,6] }' locale="es" 
                            :min-date="new Date('2024-04-29')" :max-date="new Date()" :disabled="IsLoader.BloqCmb1">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input class="form-control cal" placeholder="Seleccione una Fecha" :value="inputValue" v-on="inputEvents" readonly/>
                                </template>
                            </v-date-picker> -->

                            <v-date-picker v-model="objFiltroFechasGlob" @input="blockPrint()" mode="date" :model-config="modelConfig" is-range  :timezone="timezone" :isUTC="true" >
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="flex justify-center items-center">
                                        <input :value="inputValue.start+' - '+inputValue.end" v-on="inputEvents.start" class="form-control mr-1"  readonly/>
                                    </div>
                                </template>
                            </v-date-picker>
                        </div>

                        <button type="button" @click="getInforme()" v-b-tooltip.hover.Top title="Cargar Reporte" class="btn btn-primary btn-sm mr-2">
                            <i v-show="(!IsLoader.BtnReporte.Bloq)" class="fas fa-file-chart-line"></i>
                            

                            <i v-show="(IsLoader.BtnReporte.Bloq)" :class="IsLoader.DefaultSpiner"></i>
                            {{IsLoader.BtnReporte.Icon}}
                        </button>

                        <button v-if="showImprimirPdf" type="button" v-b-tooltip.hover.Top @click="viewPdfReporte()" title="Descargar Reporte PDF" class="btn btn-danger btn-sm mr-2">
                            <i class="far fa-print"></i>    
                        </button>
                    </div>
                </form>

            </template>
                        
            <template slot="bodyForm">

                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">

                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">
                                    Desglose de Ordenes Producción                              
                                </a>
                            </li>
                            
                        </ul>
                        <div class="tab-content shadow-sm" id="myTabContent">

                            <!--Parte Dos Prestamo -->
                            <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div class="container-fluid">

                                    <!-- Creditos Vigentes -->
                                    <fieldset>

                                        <legend class="col-form-label">&nbsp;Resultado de Reporte&nbsp;</legend>
                                        
                                        <CTablita :pConfigList="ConfigListProductividad">
                                            <template slot="header">
                                                <th>Cliente</th>
                                                <th>Folio Orden Prod.</th>
                                                <th>Fecha Entrega</th>
                                                <th>Estatus Orden</th>
                                                <th>Producto</th>
                                                <th class="text-right">Cantidad</th>

                                            </template>
                                            <template slot="body">

                                                <tr v-for="(item1,index1) in listDesgloceOrdenesProduccion"  :key="index1">
                                                    <template v-if="item1.TipoRow == 1"> 
                                                        <td  colspan="6" class="text-center" style="background-color: #ccd1d1;"><h6 style="color: #2c3e50;">{{ item1.Fecha }}</h6> </td>
                                                    </template>
                                                    <template v-else>
                                                        <td>
                                                            <CAvatar :fullname="item1.RazonSocial" :color="item1.color" :size="25" :radius="50" v-b-tooltip.hover.Top :title="item1.RazonSocial" style="margin-bottom: 0px;"/>&nbsp;
                                                            <b>{{ item1.NombreComercial }}</b>
                                                            
                                                        </td>
                                                        <td>{{ item1.FolioOrden }}</td>
                                                        <td>{{ item1.FechaEntrega }}</td>
                                                        <td class="">
                                                            <b-badge v-if="item1.EstatusOrden == 'Creada'" pill variant="secondary">{{ item1.EstatusOrden }}</b-badge>
                                                            <b-badge v-if="item1.EstatusOrden == 'Autorizada'" pill variant="warning">{{ item1.EstatusOrden }}</b-badge>
                                                            <b-badge v-if="item1.EstatusOrden == 'Pendiente'" pill variant="primary">{{ item1.EstatusOrden }}</b-badge>
                                                            <b-badge v-if="item1.EstatusOrden == 'EnTransito'" pill variant="info">{{ item1.EstatusOrden }}</b-badge>
                                                            <b-badge v-if="item1.EstatusOrden == 'Entregada'" pill variant="success">{{ item1.EstatusOrden }}</b-badge>
                                                            <b-badge v-if="item1.EstatusOrden == 'Cancelada'" pill variant="danger">{{ item1.EstatusOrden }}</b-badge>
                                                        </td>
                                                        <td>{{ item1.Descripcion }}</td>
                                                        <td class="text-right"><b>{{ item1.Cantidad }}</b></td>
                                                    </template>
                                                    
                                                    

                                                    

                                                </tr>
                                               

                                                <CSinRegistros :pContIF="listDesgloceOrdenesProduccion.length" :pColspan="6" />
                                            </template>
                                            <!--<template slot="fotter">
                                                <tr v-if="listProductividad.length > 0" style="background-color: #e9ecef;">
                                                    <td></td>
                                                    <td class="text-right"><strong>Totales</strong> </td>
                                                    <td class="text-center"><strong>{{ productividadTotales.TotPorcentDia1 }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.TotalDia1,'$') }}</strong></td>

                                                    <td class="text-center"><strong>{{ productividadTotales.TotPorcentDia2 }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.TotalDia2,'$') }}</strong></td>

                                                    <td class="text-center"><strong>{{ productividadTotales.TotPorcentDia3 }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.TotalDia3,'$') }}</strong></td>

                                                    <td class="text-center"><strong>{{ productividadTotales.TotPorcentDia4 }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.TotalDia4,'$') }}</strong></td>

                                                    <td class="text-center"><strong>{{ productividadTotales.TotPorcentDia5 }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.TotalDia5,'$') }}</strong></td>

                                                    <td class="text-center"><strong>{{ productividadTotales.TotPorcentDia6 }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.TotalDia6,'$') }}</strong></td>


                                                    <td class="text-center"><strong>{{ productividadTotales.PromRutaSemana }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.SumGranTotal,'$') }}</strong></td>
                                                </tr>
                                            </template> -->
                                        </CTablita>
                                    </fieldset>

                                   
                                   
                                        
                                    

                                </div>
                            </div>

                        </div>
                    </template>
                </CLoader>

            </template>
        </CList>

      
    </div>
</template>


<script>
import moment from "moment";
import CSinRegistros from "../../../components/CSinRegistros";
import CLoader       from "../../../components/CLoader";
import CList         from "../../../components/CList";
import CTablita         from "../../../components/CTablita";

const EmitEjecuta       = "seccionReporteResumenSemana";
const EmitComentario    = "seccionComentarioReporte";

export default {
    name:  "viewverVentasSemanales",
    props: [],
    components: {
        CSinRegistros,
        CLoader,
        CList,
        CTablita,
    },

    data() {
        return {
            ListaSucursalesArray:[],
            IdSucursalGlob:0,
            ShowCmbSucursales: false,
            masks: {
                input: "YYYY-MM-DD",
            },
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
            timezone:'America/Mexico_City',

            objFiltroFechasGlob: {
                start:'',
                end:''
            },
            FiltroFechaGlob: '',
            arrayColorUser:[],

            ConfigLoad:{
                ShowLoader: false,
                ClassLoad:  true,
            },
            showImprimirPdf: false,
            IsLoader:{
				BtnReporte:{
					Bloq: false,
					Icon: 'Cargar Reporte',
					Orig: 'Cargar Reporte',
				},
				DefaultSpiner: 'fa fa-spinner fa-pulse fa-1x fa-fw',
				DefaultText: ' Espere...',
				BloqCmb1: true,
				
			},

            ConfigList: {
                ShowTitleFirst:    true,
                TitleFirst:        "Menu Reportes",
                Title:             "Reporte de Desglose de Ordenes Producción",
                ShowLoader:        true,
                IsModal:           false,
                BtnNewShow:        false,
                BtnReturnShow:     false,
                TypeBody:          "Form",
                ShowFiltros:       false,
                ShowFiltrosInline: true,
                ShowTitleInline:   false,
                ShowPaginador:     false,
            },
            oBtnSave: {
                toast:         0,
                IsModal:       false,
                ShowBtnSave:   true,
                ShowBtnCancel: false,
                DisableBtn:    false,
                EmitSeccion:   EmitEjecuta,
            },
            
            // Productividad
            listDesgloceOrdenesProduccion: [],
            productividadTotales: {
                TotalDia1: 0, ProcentDia1: 0,
                TotalDia2: 0, ProcentDia2: 0,
                TotalDia3: 0, ProcentDia3: 0, 
                TotalDia4: 0, ProcentDia4: 0,
                TotalDia5: 0, ProcentDia5: 0,
                TotalDia6: 0, ProcentDia6: 0,
                PromRutaSemana: 0,
                SumGranTotal: 0
            },
            semanaReporte:{
                SemanaIni: "",
                SemanaFin: ""
            },
            ConfigListProductividad: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
                GoRoute: 'formdetalleprestamo'
            },


        }
    },
    methods: {

        ListaSucursales() {
            this.$http.get("sucursales", {
                params: {
                    isSimple: 1
                },
            })
                .then((res) => {
                    let response = res.data.dataSuc;
                    this.ListaSucursalesArray = res.data.data;

                    if(response.EsAdmin == 0)
                    {
                        this.IdSucursalGlob = response.IdSucursal;
                    }
                    else
                    {
                        this.ShowCmbSucursales = true;

                        if(this.ListaSucursalesArray.length>0)
                        {
                            this.IdSucursalGlob = this.ListaSucursalesArray[0].IdSucursal;
                        }
                    }
                }).finally(()=>{
                    this.setWeekValue();
                    this.IsLoader.BloqCmb1 = false;
            });
        },

        blockPrint(){
            this.showImprimirPdf = false;
        },

        async getInforme() {
            this.limpiar();

            this.ConfigLoad.ShowLoader = true;
            this.IsLoader.BtnReporte.Bloq = true;
            this.IsLoader.BtnReporte.Icon = this.IsLoader.DefaultText;
            this.IsLoader.BloqCmb1 = true;
            this.showImprimirPdf = false;
            
           
            //this.FiltroFechaGlob.start  = moment(this.objFiltroFechasGlob.start).format('YYYY-MM-DD');
            //this.FiltroFechaGlob.end    = moment(this.objFiltroFechasGlob.end).format('YYYY-MM-DD');
            console.log(this.objFiltroFechasGlob.start);
            console.log(this.objFiltroFechasGlob.end);

            await this.$http.get("getdetalleordenesproduccion", {
				params: {
					IdSucursal: this.IdSucursalGlob,
                    FechaI: this.objFiltroFechasGlob.start,
                    FechaF: this.objFiltroFechasGlob.end,
				},
			})
			.then((res) => {
                console.log(res.data.data);
                let listaRow  = res.data.data;
                let algo = this;

                listaRow.forEach(function(element) {
                    element.color = algo.selectUserColor(element.IdCliente);
                });
                this.listDesgloceOrdenesProduccion = listaRow;

                //this.productividadTotales    = res.data.data.productividadTotales;
                //this.semanaReporte           = res.data.data.rangoSemana;
                this.showImprimirPdf        = true;
                this.$toast.success('Información Recuperada');
                
            }).catch(err=>{   
                this.$toast.error(err.response.data.message);

            }).finally(() => {
                this.ConfigLoad.ShowLoader = false;
                this.IsLoader.BtnReporte.Bloq = false;
				this.IsLoader.BtnReporte.Icon = this.IsLoader.BtnReporte.Orig;
                this.IsLoader.BloqCmb1 = false;
            });
        },

        selectUserColor(pIdCliente) {

            let obj = this.arrayColorUser.filter( e => parseInt(e.IdCliente) === parseInt(pIdCliente) );

            if (obj.length > 0) {

                return obj[0].color;
                
            }else {

                var colours       =  ['#FF0000', '#008000' ,'#0000FF' ,'#000000' ,'#800000' ,'#808000' , '#008080' ,'#000080' ,'#FF00FF' ,'#800080' ,'#808080','#FF0000','#FA8072' ,'#52BE80','#273746','#7E5109','#3498DB'];     
                const randomMonth = colours[Math.floor(Math.random() * colours.length)];
                
                this.arrayColorUser.push({
                    IdCliente: pIdCliente,
                    color: randomMonth
                })

                return randomMonth
            }

            
        },

       

        viewPdfReporte(){
            let newRequest = {
                productividad: this.listDesgloceOrdenesProduccion,
                productividadTotales: this.productividadTotales,
                rangoSemana: this.semanaReporte,
                IdSucursal: this.IdSucursalGlob,
                Fecha: this.FiltroFechaGlob,
            }

            this.$http.post('rptpdfproductividadsemanal',newRequest).then((res)=>{     
                
                let pdfWindow = window.open("");
                pdfWindow.document.write(
                    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(res.data.pdf) + "'></iframe>"
                );
                
            }).catch(err=>{   
               console.log(err);
            });

          
        },

        limpiar() {
            this.listResumenEntregas = [];
            this.productividadTotales = {
                TotalDia1: 0, ProcentDia1: 0,
                TotalDia2: 0, ProcentDia2: 0,
                TotalDia3: 0, ProcentDia3: 0, 
                TotalDia4: 0, ProcentDia4: 0,
                TotalDia5: 0, ProcentDia5: 0,
                TotalDia6: 0, ProcentDia6: 0,
                PromRutaSemana: 0,
                SumGranTotal: 0
            };
            this.semanaReporte = {
                SemanaIni: "",
                SemanaFin: ""
            };

        },

        setWeekValue() {

            let SetstartDate = moment(new Date())
                //.endOf("week")
                .toDate();

            let realstartDate = moment(SetstartDate)
                .format("YYYY-MM-DD");

            

            // Inicio de Semana
            let setInicioSemana = moment(new Date())
                .startOf("week")
                .add(1, "days")
                .toDate();

            let getInicioSemana = moment(setInicioSemana)
                .format("YYYY-MM-DD");

            // Final de Semana
            let setFinSemana = moment(new Date())
                .endOf("week")
                .add(1, "days")
                .toDate();

            let getFinSemana = moment(setFinSemana)
                .format("YYYY-MM-DD");

            this.objFiltroFechasGlob = {
                start: getInicioSemana,
                end: getFinSemana
            }

            console.log('Actual: '+realstartDate)
            console.log('InicioSemana: '+getInicioSemana)
            console.log('FinalSemana: '+getFinSemana)
        },

        Regresar(){
            this.$router.push({name:"menureportes"});
        }
    },
    created() {
        this.limpiar();
        this.bus.$off('getReporteDiario');
        this.ListaSucursales();

    },
    mounted(){
        this.bus.$on('getReporteDiario', () => {
            this.getInforme();
		});
        this.bus.$on('EmitReturn',()=> {
			this.Regresar();
		});
    }

};

</script>
<style>
.tooltip-inner {
    white-space: pre-wrap;
    max-width: 230px !important;
}
</style>