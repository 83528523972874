<template>
    <div>
        <CList :pConfigList="ConfigList" >
            <template slot="bodyForm">
                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        <input :value="preventor" type="hidden">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">
                                    Orden Producción
                                </a>
                            </li>
                        </ul>

                        <div class="tab-content shadow-sm" id="myTabContent">
                            <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;Datos del Cliente&nbsp;</legend>
                                                
                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-">

                                                                <BuscadorCliente
                                                                    ref="buscadorCliente"
                                                                    @input="getCliente()"
                                                                    v-model="objOrdenProduccion.IdCliente">
                                                                    <CValidation v-if="this.errorvalidacion.IdCliente" :Mensaje="'*' + errorvalidacion.IdCliente[0]"/>
                                                                </BuscadorCliente>

                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="RazonSocial">Razón Social </label>
                                                                <input class="form-control" id="RazonSocial" v-model="objCliente.RazonSocial" type="text" placeholder="" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                               <label for="Rfc">RFC </label>
                                                               <input class="form-control" id="Rfc" v-model="objCliente.Rfc" type="text" placeholder="" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                                <label for="RazonSocial">Folio Orden </label>
                                                                <input class="form-control" id="RazonSocial" v-model="objOrdenProduccion.FolioOrden" type="text" placeholder="" disabled/>
                                                           </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row ">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="Contacto">Contacto </label>
                                                                <input class="form-control" id="Contacto" v-model="objCliente.Contacto" type="text" placeholder="" disabled/>
                                                           </div>

                                                           
                                                           <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                               <label for="Direccion">Dirección </label>
                                                               <input class="form-control" id="Direccion" v-model="objCliente.Direccion" type="text" placeholder="" disabled/>
                                                           </div>
                                                       </div>

                                                   </div>
                                               </div>

                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                        
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                                                                <label for="Telefono">Telefono </label>
                                                                <input class="form-control" id="Telefono" v-model="objCliente.Telefono" type="text" placeholder="" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                                                <label for="Correo">Correo </label>
                                                                <input class="form-control" id="Correo" v-model="objCliente.Correo" type="text" placeholder="" disabled/>
                                                            </div>

                                                            <!--<div class="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                                                                <label for="OpNoOrdenCliente">No orden de compra del cliente <small>(opcional)</small> </label>
                                                                <input class="form-control" id="OpNoOrdenCliente" v-model="objOrdenProduccion.OpNoOrdenCliente" type="text" placeholder=""/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                                                <div class="form-check checkbox-lg ml-2 mt-3">
                                                                    <input v-model="objOrdenProduccion.IsNoOrdenGeneral" type="checkbox" class="form-check-input" id="exampleCheck1">
                                                                    <label class="form-check-label" for="exampleCheck1">¿Aplicar el No. de orden para todos los pedidos?</label>
                                                                </div>
                                                            </div> -->
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" v-if="parseInt(objOrdenProduccion.IdCliente) > 0">
                                                                <label for="Contacto">Compra con IVA </label>
                                                                <input class="form-control" id="Contacto" :value="[objOrdenProduccion.ConIva ? 'SI' : 'NO']" type="text" v-bind:style="colorInput(objOrdenProduccion.ConIva)" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                           </fieldset>
                                       </div>
                                   </div>

                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;General de la Orden &nbsp;</legend>
                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div class="form-row">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                                <div class="form-group form-row">
                                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                        <label for="Contacto">Fecha Entrega <span class="text-danger">*</span></label>
                                                                        <!--:min-date="fechaMinima()"-->
                                                                        <v-date-picker :masks="masks" :popover="{ visibility: 'focus'}" locale="mx" v-model="objOrdenProduccion.FechaEntrega" >
                                                                            <template v-slot="{ inputValue, inputEvents }">
                                                                                <input class="form-control cal" name="FechaEntrega[]" placeholder="0000-00-00" :value="inputValue" v-on="inputEvents" readonly />
                                                                            </template>
                                                                        </v-date-picker>
                                                                        <CValidation v-if="this.errorvalidacion.FechaEntrega" :Mensaje="'*'+errorvalidacion.FechaEntrega[0]"/>
                                                                    </div>

                                                                    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                                        <label for="Observaciones">Observaciones </label>
                                                                        <textarea id="Observaciones" placeholder="Ingrese Observaciones..." rows="1" wrap="soft" class="form-control" v-model="objOrdenProduccion.Observaciones"></textarea>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 mt-1">
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;Relación de Pedidos &nbsp;</legend>

                                                <div class="row justify-content-center">

                                                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 text-right">
                                                        <button v-if="objOrdenProduccion.EstatusOrden == 'Creada' || objOrdenProduccion.EstatusOrden == '' " type="button" @click="buscaPedidos()" class="btn btn-primary btn-sm float-right mt-1 mb-1">
                                                                <i class="fas fa-plus-circle"></i> Buscar Pedidos
                                                        </button>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                                                            <table class="table table-responsive-lg">
                                                                <thead>
                                                                <tr>
                                                                    <th class="td-sm"></th>
                                                                    <th>Folio</th>
                                                                    <th>Fecha Lev.</th>
                                                                    <th>Sucursal</th>
                                                                    <th>Total</th>
                                                                    <th></th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr v-for="(pedido,indx) in objPedidosxOrden" :key="indx">
                                                                    <td>{{(indx + 1)}}</td>
                                                                    <td>{{pedido.FolioPedido}}</td>
                                                                    <td>{{$getCleanDate(pedido.FechaLevantamiento,false)}}</td>
                                                                    <td>{{pedido.clientesucursal.Nombre}}</td>
                                                                    <td>{{pedido.Total}}</td>
                                                                    <td>
                                                                        <button type="button" v-b-tooltip.hover.Top title="Ver Detalle" class="btn btn-primary btn-icon mr-1" @click="verDetallePedido(pedido.detalles)">
                                                                            <i class="fas fa-eye"></i>
                                                                        </button>
                                                                        <button v-if="objOrdenProduccion.EstatusOrden == 'Creada' || objOrdenProduccion.EstatusOrden == '' " type="button" v-b-tooltip.hover.Top title="Eliminar" class="btn btn-danger btn-icon mr-1" @click="eliminarPedidoOrden(indx)">
                                                                            <i class="fas fa-trash-alt"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <CSinRegistros :pContIF="objPedidosxOrden.length" :pColspan="6" />

                                                                </tbody>
                                                            </table>
                                                        </div>


                                                </div>

                                            </fieldset>
                                        </div>
                                    </div>


                                   <div class="row mt-3">
                                       <div class="col-12">
                                           <fieldset>
                                               <legend class="col-form-label">&nbsp;Contenido Orden de Produccion &nbsp;</legend>

                                               <CTablita :pConfigList="ConfigListDetalle">
                                                   <template slot="header">
                                                       <th>Part.</th>
                                                       <th>Descripcion</th>
                                                       <th class="text-center">Cantidad</th>
                                                       <th class="text-center">Precio Unitario</th>
                                                       <th>IVA</th>
                                                       <th>Importe</th>

                                                   </template>
                                                   <template slot="body">
                                                       <tr  v-for="(item,index) in objDetalleOrden"  :key="index">

                                                           <td>{{ index+1 }}</td>
                                                           <td>
                                                               <textarea v-model="item.Descripcion"
                                                                   class="form-control form-control-sm"
                                                                   rows="2" readonly>
                                                               </textarea>
                                                            </td>
                                                           <td class="text-center">

                                                            <vue-numeric class="form-control form-control-sm" v-model="item.Cantidad" @input="hacerCalculos(index, item)"
                                                                    currency="" separator="," v-bind:min="0" :precision="0" style="width:90px" :read-only="true" :empty-value="0"
                                                                    />
                                                           </td>

                                                           <td class="text-center">
                                                            <vue-numeric class="form-control form-control-sm" v-model="item.PrecioUnitario" @input="hacerCalculos(index, item)"
                                                                    currency="$"  separator=","  v-bind:min="0" :precision="2"  style="width:120px" :read-only="true" :empty-value="0"
                                                                    />
                                                           </td>
                                                           <td>
                                                                <span v-if="parseInt(item.ConIva) > 0">SI</span>
                                                                <span v-else>NO</span>
                                                            </td>

                                                           <td>
                                                            <b>
                                                                <vue-numeric class="form-control form-control-sm" v-model="item.Importe" currency="$" separator=","
                                                                    v-bind:min="0" :precision="2"  style="width:120px" :read-only="true" :empty-value="0"
                                                                 />
                                                            </b>

                                                           </td>

                                                       </tr>
                                                       <CSinRegistros :pContIF="objDetalleOrden.length" :pColspan="6" />
                                                   </template>
                                               </CTablita>

                                               <div class="form-row">
                                                   <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                       <div class="form-row">
                                                           <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                           </div>
                                                           <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>
                                                           <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>

                                                           <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                               <div class="form-group form-row">
                                                                   <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                                       <label>Subtotal:</label>
                                                                   </div>
                                                                   <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                                       <vue-numeric
                                                                            class="form-control form-control-sm"
                                                                            v-model="objOrdenProduccion.Subtotal"
                                                                            placeholder="$0.00"
                                                                            currency="$"
                                                                            separator=","
                                                                            :precision="2"
                                                                            :disabled="true"
                                                                            :empty-value="0.00"
                                                                        />
                                                                   </div>
                                                               </div>

                                                               <div class="form-group form-row mt-4">
                                                                   <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                                      <!-- <div class="form-check">
                                                                           <input v-model="objOrdenProduccion.ConIva"  @change="hacerCalculos" type="checkbox" class="form-check-input" id="exampleCheck1">
                                                                           <label class="form-check-label" for="exampleCheck1">IVA:</label>
                                                                       </div>-->
                                                                       <label>IVA:</label>
                                                                   </div>
                                                                   <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                                       <vue-numeric
                                                                            class="form-control form-control-sm"
                                                                            v-model="objOrdenProduccion.Iva"
                                                                            placeholder="$0.00"
                                                                            currency="$"
                                                                            separator=","
                                                                            :precision="2"
                                                                            :disabled="true"
                                                                            :empty-value="0.00"
                                                                        />
                                                                   </div>
                                                               </div>


                                                               <div class="form-group form-row mt-4">
                                                                   <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                                       <label>Total:</label>
                                                                   </div>
                                                                   <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                                        <vue-numeric
                                                                            class="form-control form-control-sm"
                                                                            v-model="objOrdenProduccion.Total"
                                                                            placeholder="$0.00"
                                                                            currency="$"
                                                                            separator=","
                                                                            :precision="2"
                                                                            :disabled="true"
                                                                            :empty-value="0.00"
                                                                        />
                                                                   </div>
                                                               </div>

                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>
                                           </fieldset>
                                       </div>
                                   </div>
                                </div>
                           </div>

                            <CBtnSave :poBtnSave="oBtnSave" />
                       </div>
                   </template>
               </CLoader>
           </template>
       </CList>


        <CModal :pConfigModal="ConfigModalSelectPedidos" :poBtnSave="poBtnSaveSelectPedidos">
            <template slot="Form">
                <ListPedidosPendiente :poBtnSave="poBtnSaveSelectPedidos" :pPedidosAgregados="objPedidosxOrden" />
            </template>
        </CModal>

        <CModal :pConfigModal="ConfigModalDetPedido" :poBtnSave="poBtnSaveDetPedido">
            <template slot="Form">
                <viewDetallePedido :poBtnSave="poBtnSaveDetPedido"/>
            </template>

        </CModal>

   </div>
</template>
<script>
import moment from "moment"
import CList from "@/components/CList.vue";
import CValidation from "@/components/CValidation.vue";
import CLoader from "@/components/CLoader.vue";
import Template from "@/views/template/Template.vue";
import CBtnSave from "@/components/CBtnSave.vue";
import BuscadorCliente from "@/components/BuscadorCliente.vue";
import CTablita from "@/components/CTablita.vue";
import CBtnAccion from "@/components/CBtnAccion.vue";
import CSinRegistros from "@/components/CSinRegistros.vue";
import CModal from "@/components/CModal.vue";
import ListPedidosPendiente from "@/views/modulos/produccion/ordenesproduccion/ListPedidosPendiente.vue";
import viewDetallePedido from "@/views/modulos/produccion/ordenesproduccion/viewDetallePedido.vue";


const EmitEjecuta                   = 'seccionFormProduccion';
const EmitEjecutaDetalle            = 'seccionFormDetalle';
const EmitEjecutaSelectPedidos      = 'seccionSelectPedidos';
const EmitEjecutaModalDetallePed    = 'seccionModalDetalle';

export default {
    name: "FormOrdenProduccion",
    props: ["Id"],
    components: {
        Template,
        CList,
        CValidation,
        CLoader,
        CBtnSave,
        BuscadorCliente,
        CTablita,
        CBtnAccion,
        CSinRegistros,
        CModal,
        ListPedidosPendiente,
        viewDetallePedido
    },

    data() {
        return {
            errorvalidacion:[],
            segurity:{},
            masks: {
                input: "YYYY-MM-DD",
            },

            ConfigList: {
                ShowTitleFirst:    false,
                Title:             "Detalle Orden Producción",
                ShowLoader:        true,
                IsModal:           false,
                BtnNewShow:        false,
                BtnReturnShow:     true,
                TypeBody:          "Form",
                ShowFiltros:       false,
                ShowFiltrosInline: true,
                ShowTitleInline:   false,
                ShowPaginador:     false,
            },

            ConfigLoad: {
                ShowLoader: true,
                ClassLoad:  false,
            },

            oBtnSave: {
                toast:         0,
                IsModal:       false,
                ShowBtnSave:   true,
                ShowBtnCancel: true,
                DisableBtn:    false,
                EmitSeccion:   EmitEjecuta,
                ReturnRoute:   'ordenesproduccion'
            },

            BlockerDates:{
                BlockFecha:true,
                BlockHoraI:false,
                BlockHoraF:false,
                FechaActual:'',
                FechaAyer:'',
                isPast: false
            },

            objOrdenProduccion:{
                IdOrdenProduccion: 0,
                IdCliente: 0,
                IdClienteSucursal: 0,
                FolioOrden: '',
                FechaLevantamiento: '',
                FechaEntrega: '',
                Subtotal: 0,
                Iva: 0,
                Total: 0,
                Observaciones: '',
                ConIva: true,
                EstatusOrden:    '',
                OpNoOrdenCliente: '',
                IsNoOrdenGeneral: 0
            },

            objDetalleOrden:[],

            objCliente:{
                NombreComercial: '',
                RazonSocial: '',
                Rfc: '',
                Contacto: '',
                Telefono: '',
                Correo: '',
                Direccion:'',
                Calle: '',
                NoInt: '',
                NoExt: '',
                Colonia: '',
                Cp: '',
                Cruzamiento1: '',
                Cruzamiento2: '',
                Referencias: '',
                IdEstado: '',
                IdMunicipio: '',
            },

            ConfigListDetalle:{
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecutaDetalle,
            },

            // CONTROL PEDIDOS X ORDEN
            objPedidosxOrden:[],

            ConfigModalSelectPedidos: {
                ModalTitle:  "Pedidos Pendientes",
                ModalNameId: 'ModalForm',
                EmitSeccion: EmitEjecutaSelectPedidos,
                ModalSize:   'lg',
                ShowFooter: true,
            },

            poBtnSaveSelectPedidos:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecutaSelectPedidos,
                ShowBtnSave: true,
                IdObjeto: 0,
            },

           // MODAL DE DETALLE PEDIDOS
            ConfigModalDetPedido: {
                ModalTitle:  "Detalle Pedido",
                ModalNameId: 'ModalForm',
                EmitSeccion: EmitEjecutaModalDetallePed,
                ModalSize:   'lg',
                ShowFooter: true,
            },
            poBtnSaveDetPedido:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecutaModalDetallePed,
                ShowBtnSave: false,
                IdObjeto: 0,
            },

        }
    },

    methods: {
        
        getCliente() {
            
            this.$http.get( `/clientes/${this.objOrdenProduccion.IdCliente}` )
            .then( res => {
                
                const cliente = res.data.data.Cliente;
                this.objCliente = cliente;

                //this.setCliente(this.objCliente);

                // SE OBTIENE LA CONFIGURACION DE IVA DEL CLIENTE,
                // ESTO DETERMINA SI EL PEDIDO LLEVA O NO IVA, DE LOS PRODUCTOS QUE SI TENGAN IVA

                if (parseInt(this.objCliente.CompraConIva) > 0) {
                    this.objOrdenProduccion.ConIva = true;

                }else {
                    this.objOrdenProduccion.ConIva = false;
                }

            }).catch( err => {

            this.limpiaCliente();
            });
        },

        setCliente( cliente = {} ) {
            
            const buscadorCliente = this.$refs.buscadorCliente;
            buscadorCliente.clientes.push({
                label: `${cliente.NombreComercial}`,
                key: cliente.IdCliente,
                detalle: {
                    direccion: Contacto,
                },
            });

        },

        limpiaOrden(){
            this.BlockerDates ={
                BlockFecha:true,
                BlockHoraI:false,
                BlockHoraF:false,
                FechaActual:'',
                FechaAyer:'',
                isPast: false
            };

            this.objOrdenProduccion = {
                IdOrdenProduccion: 0,
                IdCliente: 0,
                IdClienteSucursal: 0,
                FolioOrden: '',
                FechaLevantamiento: '',
                FechaEntrega: '',
                Subtotal: 0,
                Iva: 0,
                Total: 0,
                Observaciones: '',
                ConIva: true,
                EstatusOrden:    '',
                OpNoOrdenCliente: '',
                IsNoOrdenGeneral: 0
            };

            this.objDetalleOrden = [];
            this.objPedidosxOrden = [];
        },

        limpiaCliente(){
            this.objCliente = {
                NombreComercial: '',
                RazonSocial: '',
                Rfc: '',
                Contacto: '',
                Telefono: '',
                Correo: '',
                Direccion:'',
                Calle: '',
                NoInt: '',
                NoExt: '',
                Colonia: '',
                Cp: '',
                Cruzamiento1: '',
                Cruzamiento2: '',
                Referencias: '',
                IdEstado: '',
                IdMunicipio: '',
            }
        },

        Recuperar() {
            this.$http.get("ordenesproduccion/"+this.objOrdenProduccion.IdOrdenProduccion)
                .then((res) => {

                    let result = res.data.data;
                    result.cliente.IdCliente             = parseInt(result.cliente.IdCliente);
                    this.objOrdenProduccion 				= result;
                    this.objOrdenProduccion.ConIva                   = parseInt(this.objOrdenProduccion.ConIva) > 0 ? true : false;
                    this.objOrdenProduccion.IsNoOrdenGeneral         = parseInt(this.objOrdenProduccion.IsNoOrdenGeneral) > 0 ? true : false;
                    let Fecha1 						    = this.objOrdenProduccion.FechaEntrega.replace(/-/g,'\/');
                    this.objOrdenProduccion.FechaEntrega = Fecha1;
                    this.objCliente                      = this.objOrdenProduccion.cliente;
                    this.setCliente(this.objCliente);
                    this.objDetalleOrden                 = this.objOrdenProduccion.detalles;
                    this.objPedidosxOrden                = this.objOrdenProduccion.PedidosxOrden;


                }).finally(() => {
                this.hacerCalculos();
                this.ConfigLoad.ShowLoader = false;

            });
        },

        Guardar(){
                let Fecha1 = '';
            if(this.objOrdenProduccion.FechaEntrega !=''){
                Fecha1 = moment(this.objOrdenProduccion.FechaEntrega).format('YYYY-MM-DD');
            }
            this.objOrdenProduccion.FechaEntrega = Fecha1;

            const bodyParams = {
                IdOrdenProduccion: this.objOrdenProduccion.IdOrdenProduccion,
                IdCliente: this.objOrdenProduccion.IdCliente,
                FechaEntrega: this.objOrdenProduccion.FechaEntrega,
                ConIva: this.objOrdenProduccion.ConIva,
                Subtotal: this.objOrdenProduccion.Subtotal,
                Iva: this.objOrdenProduccion.Iva,
                Total: this.objOrdenProduccion.Total,
                Observaciones: this.objOrdenProduccion.Observaciones,
                OrdenProdCliente: this.objOrdenProduccion.OrdenProdCliente,
                    IsNoOrdenGeneral: this.objOrdenProduccion.IsNoOrdenGeneral,

                //DETALLE DE LA ORDEN DE COMPRA
                OrdenesDetalle: JSON.stringify(this.objDetalleOrden),
                PedidosxOrden: JSON.stringify(this.objPedidosxOrden),
            };

            this.$http.post( '/ordenesproduccion', bodyParams )
            .then( res  => {
                this.EjecutaConExito(res);

            })
            .catch( err => {
                this.EjecutaConError(err);
            });
        },

        EjecutaConExito(res) {
            this.oBtnSave.DisableBtn = false;
            this.bus.$emit('RunAlerts_'+this.ConfigList.EmitSeccion,1);
            this.Regresar();
        },

        EjecutaConError(err) {
            this.oBtnSave.DisableBtn = false;

            if (err.response.data.errors) {
                this.errorvalidacion = err.response.data.errors;
                this.oBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },

        Regresar() {
            this.$router.push({name:'ordenesproduccion',params:{}});
        },

        fechaMinima() {
            // OBTENEMOS LA FECHA ACTUAL

            let FechaActual = moment(new Date());
            FechaActual = FechaActual.format('YYYY-MM-DD');

            this.BlockerDates.FechaActual = FechaActual;
            //console.log(this.BlockerDates.FechaActual);

            // OBTENEMOS LA FECHA DE AYER BASADA EN LA FECHA ACTUAL
            let FechaA = moment(new Date());
            FechaA = FechaA.subtract(1,"days");
            FechaA = FechaA.format('YYYY-MM-DD');


            this.BlockerDates.FechaAyer=FechaA;

            if(parseInt(this.objOrdenProduccion.IdOrdenProduccion) > 0 ){
                // SI LA FECHA INICIAL DEL SERVICIO ESTA ENTRE EL RANGO DE FECHAS DE AYER Y HOY, NO SE BLOQUEA NADA
                if (this.objOrdenProduccion.FechaEntrega >= this.BlockerDates.FechaAyer && this.objOrdenProduccion.FechaEntrega <= this.BlockerDates.FechaActual ) {
                    this.BlockerDates.BlockFecha = null;
                    this.BlockerDates.isPast     = (this.objOrdenProduccion.FechaEntrega < this.BlockerDates.FechaActual) ? true: false;

                }else{
                    //
                    if(this.objOrdenProduccion.FechaEntrega > this.BlockerDates.FechaActual ) {
                        this.BlockerDates.BlockFecha = false;
                    }else {
                        this.BlockerDates.BlockFecha = true;
                    }
                }

                if(this.BlockerDates.BlockFecha) {
                    return null

                } else{
                    let fecha = '';
                    if(this.BlockerDates.isPast) {
                        fecha = moment(this.BlockerDates.FechaAyer).add(1,'day').format('YYYY-MM-DD');
                        fecha = new Date(fecha);

                    }else {
                        fecha = moment(new Date()).add(1,'day').format('YYYY-MM-DD');
                        fecha = new Date(fecha);
                    }


                    return fecha;
                }

            }else {
                let fecha = '';
                fecha = moment(new Date()).add(1,'day').format('YYYY-MM-DD');
                fecha = new Date(fecha);
                return fecha;
            }

        },

        //------------------------ PEDIDOS RECUPERACION ---------------------------------

        buscaPedidos(){
            if(parseInt(this.objOrdenProduccion.IdCliente) > 0){
                let obj = {
                    IdCliente: this.objOrdenProduccion.IdCliente,
                    IdOrdenProduccion: this.objOrdenProduccion.IdOrdenProduccion
                }
                this.bus.$emit('NewModal_'+EmitEjecutaSelectPedidos,obj);
            }else {
                this.$toast.warning('Necesita seleccionar un cliente primero','Advertencia','');
            }
        },

        verDetallePedido(item){
            this.bus.$emit('NewModal_'+EmitEjecutaModalDetallePed,item);
        },

        eliminarPedidoOrden(indx) {

            this.objPedidosxOrden.splice( indx, 1 );
            this.verificarProductos();
        },

        agregaPedidoOrden(pedido) {

            let arrDetalle = this.objPedidosxOrden.filter(function(item,index){
                if(item.IdPedido == pedido.IdPedido){
                    return item;
                } else {
                    return '';
                }
            });

            if(arrDetalle[0]) {
                // NOTIFICAR QUE EL PEDIDO YA ESTA EN LA LISTA;
                this.$toast.warning('El pedido ya se ha agregado a la orden de compra','Pedido Repetido','');


            }else {
                // SI EL PEDIDO NO ESTA EN LA LISTA DE LOS PEDIDO AGREGADOS A LA ORDEN, ESTE SE AGREGA A LA LISTA
                this.objPedidosxOrden.push(pedido);
                this.verificarProductos();

            }

        },

        verificarProductos() {

            this.objDetalleOrden = [];
            let arraysumProd = [];


            // SE RECORRE LA LISTA DE PEDIDOS AGREGADOS A LA ORDEN DE COMPRA
            let countAgregados = 0;
            this.objPedidosxOrden.forEach( (element) => {

                // SE RECORRE EL DETALLE DE CADA PEDIDO PARA PODER OBTENER LOS AGRUPADOS DE LOS PRODUCTOS
                element.detalles.forEach( (detalle) => {

                    // SE VERIFICA SI EL PRODUCTO DEL DETALLE DEL PEDIDO, YA SE ENCUENTRA EN LA LISTA DE PRODUCTOS PARA LA ORDEN
                    let arr = arraysumProd.filter(function(item,index){
                        if(item.IdProducto == detalle.IdProducto){
                            return item;
                        } else {
                            return '';
                        }
                    });
                        // SI EL PRODUCTO YA EXISTE EN LA LISTA GENERAL DE PRODUCTO DE LA ORDEN, SE PROCEDE A SUMAR LA CANTIDAD DEL PRODUCTO YA REPETIDO
                    // DE FORMA QUE AUMENTE LA CANTIDAD DEL PRODUCTO REPETIDO CON FORME A LA CANTIDAD SOLICITADA EN LOS PEDIDOS
                    if(arr[0]) {

                        const IdProducto = arr[0].IdProducto;
                        const index = arraysumProd.findIndex(item => parseInt(item.IdProducto) === IdProducto);

                        if(index !== -1) {
                            arraysumProd[index].Cantidad = ( parseFloat(arraysumProd[index].Cantidad) + parseFloat(detalle.Cantidad) );
                            arraysumProd[index].Importe  = (parseFloat(arraysumProd[index].Cantidad) * parseFloat(arraysumProd[index].PrecioUnitario) );

                            // NOTA: SE RETIRA MENSAJE POR SER INVASIVO CON MULTIPLES PEDIDOS AGREGADOS.
                            // SE REMPLAZA POR UN UNICO MENSAJE.
                            countAgregados++;
                            //let msg = 'Se han agregado +'+parseInt(detalle.Cantidad)+' '+detalle.Descripcion 
                            //this.$toast.success(msg,'Producto Agregado','');
                        }

                    }else {
                        // SI EL PRODUCTO NO EXISTE EN LA LISTA DE PRODUCTOS GENERALES DE LA ORDEN, SE PROCEDE A AGREGARLO DIRECTAMENTE A LA LISTA
                        countAgregados++;
                        arraysumProd.push({
                            IdProducto: parseInt(detalle.IdProducto),
                            Descripcion: (detalle.Descripcion),
                            Cantidad: parseFloat(detalle.Cantidad),
                            PrecioUnitario: parseFloat(detalle.PrecioUnitario),
                            Importe: parseFloat(detalle.Importe),
                            ConIva: parseInt(detalle.ConIva)
                        });
                    }
                });

                

            });

            if(countAgregados > 0) {
                this.$toast.success('Pedido Agregado','','');
            }

            this.objDetalleOrden = arraysumProd;
            this.hacerCalculos();
        },

        hacerCalculos() {

            const tazaIva           = 0.16;
            let subtotal            = 0.00;
            let iva                 = 0.00;
            let total               = 0;
            let totalIvaProductos   = 0.00;

            this.objDetalleOrden.forEach( (element) => {
                let pUnitario = 0;
                let cantidad = 0;
                let importeFinal = 0;

                // EVALUAR SI EL MONTO ES NAN O MAYOR A 0
                if(!isNaN(element.PrecioUnitario) && parseFloat(element.PrecioUnitario) > 0){
                    pUnitario = parseFloat(element.PrecioUnitario);
                }

                // EVALUAR SI EL MONTO ES NAN O MAYOR A 0
                if(!isNaN(element.Cantidad) && parseFloat(element.Cantidad) > 0){
                    cantidad = parseFloat(element.Cantidad);
                }

                importeFinal = (pUnitario * cantidad)
                element.Importe =  importeFinal.toFixed(2);

                // VERIFICAR Y CALCULAR EL VALOR DEL IVA DEL TOTAL DE PRODUCTO
                if(parseInt(element.ConIva) > 0){
                        totalIvaProductos += (parseFloat(importeFinal) * tazaIva)
                    }


                subtotal += parseFloat(element.Importe);

            });


            this.objOrdenProduccion.Subtotal = subtotal.toFixed(2);

            // EVALUAR SI SE REQUIERE O NO EL IVA DEL SUBTOTAL
            if(this.objOrdenProduccion.ConIva) {
                /*iva     = (subtotal * 0.16);
                iva     = isNaN(iva) ? 0 : iva;*/

                total   = (totalIvaProductos + subtotal)
                total   = isNaN(total) ? 0 : total;

                this.objOrdenProduccion.Iva     = totalIvaProductos.toFixed(2);
                this.objOrdenProduccion.Total   = total.toFixed(2);

            }else {
                this.objOrdenProduccion.Iva     = iva.toFixed(2);
                this.objOrdenProduccion.Total   = subtotal.toFixed(2);
            }
        },

        colorInput(type) {
                let color  = 'color: #fff; background-color: #6c757d;';

                if(type){
                    // VERDE
                    color = 'color: #1aae6f; background-color: #afe2cc; font-weight:bold; border-color:#1aae6f;';
                }else {
                    // ROJO
                    color  = 'color: #f80031; background-color: #fcacbc; font-weight:bold; border-color:#f80031;';
                }
                return color
            },


    },
    computed: {
        preventor: function(){
                if(this.objOrdenProduccion.IdCliente == null){
                    this.limpiaCliente();
                    this.limpiaOrden();

                }
            }
    },
    created() {
        this.bus.$off("Save_"+EmitEjecuta);
        this.bus.$off('pAsignarPedidoPen');
    },
    
    mounted() {
        this.limpiaOrden();
        this.limpiaCliente();

        if (this.Id > 0) {
            this.objOrdenProduccion.IdOrdenProduccion = this.Id;
            this.Recuperar();

        } else {
            this.ConfigLoad.ShowLoader = false;
        }

        this.bus.$on("Save_"+EmitEjecuta, () => {
            this.Guardar();
        });

        this.bus.$on("EmitReturn", () => {
            this.Regresar();
        });


        this.bus.$on('pAsignarPedidoPen',(obj)=> {
            this.agregaPedidoOrden(obj);
        });
    }

}
</script>
